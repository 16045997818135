import React, { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { Browser } from "@capacitor/browser";
import { Geolocation, Geoposition } from "@ionic-native/geolocation";
import { connect } from "react-redux";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import { Plugins } from "@capacitor/core";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonButton,
  IonBackButton,
  IonButtons,
  IonLoading,
  IonModal,
  IonCol,
  IonGrid,
  IonRow,
  IonToast,
  IonTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonChip,
  IonImg,
  IonSlide,
  IonSlides,
  useIonAlert,
  useIonToast,
  IonFooter,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  fetchShoot,
  fetchShootNotes,
  fetchClientNotes,
  fetchShootInvoice,
  updateShoot,
  emailChangeRequest,
  createNote,
  updateNote,
  deleteNote,
  updateSelectedShoot,
  updateNotes,
  shootCompletedByPhotographer,
  fetchAmenityByIds,
  createActivity,
} from "../../../redux/actions";
import {
  personOutline,
  paperPlaneOutline,
  addOutline,
  closeOutline,
  cloudUploadOutline,
  informationCircleOutline,
  cameraOutline,
  eye,
} from "ionicons/icons";
import "../style.css";
import {
  eventTimeFormat,
  chipColor,
  formatTelForSms,
} from "../../../helpers/utils";
import Accordion from "../../../components/Accordion";
import LabelDetail from "../../../components/LabelDetail";
import IconDetail from "../../../components/IconDetail";
import Note from "../../../components/Note";
import CollapseHeader from "../../../components/CollapseHeader";
import Map from "../../../components/Map";

import LocationIcon from "../../../assets/images/icons/Address.svg";
import NotesIcon from "../../../assets/images/icons/Notes.svg";
import DetailsIcon from "../../../assets/images/icons/Houseinfo.svg";
import ContactsIcon from "../../../assets/images/icons/Contacts.svg";
import ServicesIcon from "../../../assets/images/icons/Services.svg";
import PhotosIcon from "../../../assets/images/icons/Photos.svg";
import WalkthroughIcon from "../../../assets/images/icons/AerialVideo.svg";
import YoutubeIcon from "../../../assets/images/icons/Youtube.svg";
import TwilightIcon from "../../../assets/images/icons/Twilight.svg";
import DollhouseIcon from "../../../assets/images/icons/3D.svg";
import ClockIcon from "../../../assets/images/icons/Time.svg";
import DroneIcon from "../../../assets/images/icons/Drone.svg";
import axios from "axios";
import { CameraResultType, CameraSource } from "@capacitor/camera";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import ShootTypeBadge from "../../../components/ShootTypeBadge";
import ContactButtons from "../../../components/ContactButtons";
import NumberFormat from "react-number-format";
import CompleteShoot from "./CompleteShoot";

interface detailProps {
  fetchShoot: any;
  auth: any;
  fetchShootNotes: any;
  fetchClientNotes: any;
  selectedShoot: any;
  createNote: any;
  updateNote: any;
  deleteNote: any;
  invoice: any;
  updateShoot: any;
  emailChangeRequest: any;
  fetchShootInvoice: any;
  shootNotes: any;
  clientNotes: any;
  loading: boolean;
  error: any;
  updateSelectedShoot: any;
  updateNotes: any;
  shootCompletedByPhotographer: any;
  createActivity: any;
}

interface LocationError {
  showError: boolean;
  message?: string;
}

const Detail: React.FC<detailProps> = ({
  fetchShoot,
  fetchShootNotes,
  fetchClientNotes,
  selectedShoot,
  invoice,
  updateNotes,
  updateShoot,
  createNote,
  updateNote,
  deleteNote,
  emailChangeRequest,
  auth,
  shootNotes,
  clientNotes,
  fetchShootInvoice,
  loading,
  updateSelectedShoot,
  error,
  shootCompletedByPhotographer,
  createActivity,
}) => {
  // const [presentCompleteAlert] = useIonAlert();
  // const [presentReasonAlert] = useIonAlert();
  const [present] = useIonToast();
  const selectedShootId = useParams<{ id: string }>().id;
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<LocationError>({
    showError: false,
  });
  const { Camera } = Plugins;
  const [camera, setCamera] = useState<any>([]);
  const [previewImage, setPreviewImage] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>();
  const [position, setPosition] = useState<Geoposition>();
  const [selectedNote, setSelectedNote] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [noteText, setNoteText] = useState<string>("");
  const [noteAccess, setNoteAccess] = useState<any>([]);
  const [editNoteText, setEditNoteText] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [updatedShoot, setUpdatedShoot] = useState<any>(null);
  const [updatedNote, setUpdatedNote] = useState<any>(null);
  const [amenityData, setAmenityData] = useState<any>([]);
  const [isCompletedShoot, setIsCompletedShoot] = useState(false);
  const [reason, setReason] = useState("");
  const [requestReschedule, setRequestReschedule] = useState(false);
  const [markAsUpsell, setMarkAsUpsell] = useState(false);
  const [formData, setFormData] = useState<any>({
    requester: "",
    new_status: "",
    current_status: "Scheduled",
    request_reason: "",
    _request_created_at: new Date(),
  });
  const [contentFetched, setContentFetched] = useState(false);
  const [selectedTags, setSelectedTags] = useState<any>(["ADMIN"]);
  const [state, setState] = useState<any>({
    existingPhotos: [],
    newPhotos: [],
  });
  const { existingPhotos, newPhotos } = state;
  const [touched, setTouched] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  let history = useHistory();

  useEffect(() => {
    if (updatedShoot !== null) {
      checkShoot();
    }
  }, [updatedShoot]);
  useEffect(() => {
    if (updatedNote !== null) {
      checkNote();
    }
  }, [updatedNote]);
  useEffect(() => {
    fetchShoot(selectedShootId);
    fetchShootNotes(selectedShootId);
    fetchShootInvoice(selectedShootId);
  }, [touched]);
  useEffect(() => {
    if (updatedNote !== null) {
      fetchShootNotes(selectedShootId);
    }
  }, [updatedNote]);
  useEffect(() => {
    if (selectedShoot?._id === selectedShootId) {
      setContentFetched(true);
    }
    if (selectedShoot) {
      fetchClientNotes(selectedShoot?.client_id);
      setIsCompletedShoot(
        selectedShoot?.status === "Completed" ||
          selectedShoot?.status === "Processing"
      );
      // isCompleted(selectedShoot?.status);
    }
  }, [fetchClientNotes, selectedShoot, selectedShootId]);

  useEffect(() => {
    async function getAmenities() {
      let amenityIds: any = [];
      selectedShoot?.invoice?.map((inv: any) =>
        inv.products?.map((product: any) => {
          if (["SP", "MSP", "CSP"].includes(product.code)) {
            amenityIds = amenityIds.concat([...product.amenities]);
          }
        })
      );
      if (amenityIds.length) {
        const res = await fetchAmenityByIds(amenityIds);
        setAmenityData(res.data);
      }
    }
    getAmenities();
  }, [selectedShoot]);

  // websocket
  useEffect(() => {
    let socket: any;
    const photographerId = auth?.profile?._id;
    if (photographerId) {
      socket = socketIOClient.connect(process.env.REACT_APP_WS_URL as string, {
        transports: ["websocket"],
      });
      socket.emit("usageAcess", photographerId);
      socket.on("request", (data: any) => {
        console.log("Connected Successfully!.21");
      });
      socket.on(`currentNote-${selectedShootId}-notes`, (data: any) => {
        setUpdatedNote(data);
      });

      socket.on(`photographer-${photographerId}-shoots`, (data: any) => {
        setUpdatedShoot(data);
      });
    }
    // CLEAN UP THE EFFECT
    return () => {
      if (socket) {
        socket.disconnect(photographerId);
        console.log("Socket disconnected!");
      }
    };
  }, [auth]);

  const checkShoot = () => {
    const photographerId = auth?.profile?._id;
    if (updatedShoot?.events?.photographer?._id !== photographerId) {
      updateSelectedShoot("reassigned");
      setUpdatedShoot(null);
      return;
    }
    if (updatedShoot._id === selectedShoot._id) {
      updateSelectedShoot(updatedShoot);
    }
    setUpdatedShoot(null);
  };

  const imageRemove = (image: any) => {
    const removedList = camera.filter((url: any) => url !== image);
    setCamera(removedList);
  };

  const checkNote = () => {
    let notes = [...shootNotes];
    if (updatedNote !== null) {
      if (updatedNote.action === "edit") {
        let index = notes.findIndex(
          (note) => note._id === updatedNote.note._id
        );
        if (index !== -1) {
          notes[index] = updatedNote.note;
        }
      } else if (updatedNote.action === "add") {
        let find = notes.find((note) => updatedNote.note._id === note._id);
        if (!find) {
          notes.unshift(updatedNote.note);
        }
      } else if (updatedNote.action === "delete") {
        notes = notes.filter((note) => note._id !== updatedNote.note._id);
      }
      updateNotes(notes);
    }
    setUpdatedShoot(null);
  };
  const showImageModal = (noteId: any) => {
    setSelectedNote(noteId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setPreviewImage(false);
    setIsModalVisible(false);
  };
  useEffect(() => {
    getLocation();
  }, [selectedShoot]);

  const getLocation = async () => {
    setLocationLoading(true);

    try {
      const position = await Geolocation.getCurrentPosition();
      setPosition(position);
      setLocationLoading(false);
      setLocationError({ showError: false });
    } catch (e) {
      setLocationError({ showError: true, message: e.message });
      setLocationLoading(false);
    }
  };

  const onBrowserDirections = async () => {
    // On iOS, for example, open the URL in SFSafariViewController (the in-app browser)
    const { street, city, state, zip } = selectedShoot?.address;
    const formatStreetAddr = street.replace("null", "");
    const fullAddress = `${formatStreetAddr.split(" ").join("+")}+${city
      .split(" ")
      .join("+")}+${state.split(" ").join("+")}+${zip}`;

    console.log(fullAddress);
    const addrUrl = `https://www.google.com/maps/dir/?api=1&destination=${fullAddress}&travelmode=driving`;
    await Browser.open({
      url: addrUrl,
    });
  };

  const presentToast = (position: any, messageTxt: string) => {
    present({
      message: messageTxt,
      duration: 1500,
      position: position,
    });
  };

  const onViewMediaOnDashboard = async () => {
    const dashboardUrl = `${process.env.REACT_APP_WS_URL}/admin/shoots/${selectedShoot._id}?tab=tour`;
    await Browser.open({
      url: dashboardUrl,
    });
  };

  const onViewMediaOnline = async () => {
    const mediaUrl = `${process.env.REACT_APP_WS_URL}/media/u/${selectedShoot?.hsn}/${selectedShoot?.address?.street_lower_no_spaces}`;
    await Browser.open({
      url: mediaUrl,
    });
  };

  const onViewRescheduledShoot = async (id: string) => {
    console.log(id);
    const dashboardUrl = `${process.env.REACT_APP_WS_URL}/admin/shoots/${id}?tab=tour`;
    await Browser.open({
      url: dashboardUrl,
    });
  };

  const onViewOriginalShoot = async (id: string) => {
    const dashboardUrl = `${process.env.REACT_APP_WS_URL}/admin/shoots/${id}?tab=tour`;
    await Browser.open({
      url: dashboardUrl,
    });
  };

  const incompleteHandler = async () => {
    setSubmitting(true);
    const checkinData = {
      checkin_type: "Departure",
      checkin_time: new Date(),
      checkin_location: {
        geometry: {
          type: "Point",
          coordinates: [
            position?.coords?.longitude,
            position?.coords?.latitude,
          ],
        },
      },
    };
    const res = await shootCompletedByPhotographer(
      selectedShoot?._id,
      {
        status: `${requestReschedule ? "Postpone" : "Processing"}`,
        checklist: true,
        checkins: [...selectedShoot?.checkins, checkinData],
      },
      reason,
      requestReschedule,
      markAsUpsell
    );
    if (res?.status === 200) {
      setSuccessMessage(true);
      setSubmitting(false);
      // setShowCompleteModal(false);
      setShowModal(false);
      presentToast("top", "Comms notified of incomplete shoot!");

      // Create an activity
      await createActivity(
        {
          ...selectedShoot,
          currentLocation: {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          },
        },
        `${
          requestReschedule ? "🕓 Photographer requested a reschedule. " : ""
        }⚠️ Photographer marked this shoot as incomplete or changed for the following reason: ${reason} ${
          markAsUpsell ? "💸 Marked as upsell." : ""
        }`,
        auth?.user?._id
      );

      if (markAsUpsell) {
        // Post to Slack channel via Zapier
        const slackPayload = {
          photographerName: auth?.profile?.name,
          id: selectedShoot?._id,
          hsn: selectedShoot?.hsn,
          address: selectedShoot?.address?.street,
          clientFirst: selectedShoot?.client_name_first,
          clientLast: selectedShoot?.client_name_last,
          clientEmail: selectedShoot?.client_email,
          reason,
        };

        // ${photographerName} upsold services for ${hsn} at ${address} for ${clientFirst} ${clientLast} (${clientEmail}). Note: ${reason}

        try {
          await fetch("https://hooks.zapier.com/hooks/catch/412492/304jwvw/", {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(slackPayload),
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    history.push("/dashboard");
  };

  const completeHandler = async () => {
    setSubmitting(true);

    const checkinData = {
      checkin_type: "Departure",
      checkin_time: new Date(),
      checkin_location: {
        geometry: {
          type: "Point",
          coordinates: [
            position?.coords?.longitude,
            position?.coords?.latitude,
          ],
        },
      },
    };
    const res = await shootCompletedByPhotographer(selectedShoot?._id, {
      status: "Processing",
      checklist: true,
      checkins: [...selectedShoot?.checkins, checkinData],
    });
    if (res?.status === 200) {
      setSuccessMessage(true);

      setSubmitting(false);
      setShowModal(false);

      setIsCompletedShoot(true);
      presentToast("top", "Shoot completed successfully!");

      const notificationPayload = {
        hsn: selectedShoot?.hsn,
        address: selectedShoot?.address,
        clientFirst: selectedShoot?.client_name_first,
        clientLast: selectedShoot?.client_name_last,
        clientEmail: selectedShoot?.client_email,
        secondaryContact: selectedShoot?.booking_form?.secondary_contact,
      };

      await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/notifications/shoot-complete`,
        notificationPayload
      );

      const streetAddress = selectedShoot?.address?.street;

      // Send SMS to client
      const smsPayload = {
        tel: formatTelForSms(selectedShoot?.client_phone),
        message: `Great news! Your Home Snappers photographer has completed the photoshoot at ${streetAddress.replace(
          "null",
          ""
        )}, ${
          selectedShoot?.address?.city
        }. Thanks so much for trusting in Home Snappers!`,
      };

      try {
        await fetch("https://hooks.zapier.com/hooks/catch/412492/bq1ckgy/", {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(smsPayload),
        });

        // Create an activity
        await createActivity(
          {
            ...selectedShoot,
            currentLocation: {
              latitude: position?.coords?.latitude,
              longitude: position?.coords?.longitude,
            },
          },
          "Photographer marked this shoot as complete.",
          auth?.user?._id
        );
      } catch (error) {
        console.log(error);
      }
      history.push("/dashboard");
    }
  };

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
    });
    var imageUrl = `data:image/png;base64,${image.base64String}`;
    // Can be set to the src of an image now
    setCamera([...camera, imageUrl]);
  };

  const handleUpdateNote = async (noteId: string) => {
    updateNote(noteId, {
      text: editNoteText,
    });
    setEditNoteText("");
    setShowEditNoteModal(false);
  };
  defineCustomElements(window);
  const handlePhotoEdit = () => {
    setContentFetched(false);
    let notes = [...shootNotes];
    if (selectedNote !== "") {
      setIsModalVisible(false);
      const note = notes.filter((note: any) => note._id === selectedNote);
      if (state.newPhotos.length > 0) {
        const handleUpload = async () => {
          let datas = new FormData();
          datas.append("path", `shoots/notes/high/${selectedShoot?.hsn}_`);
          for (var x = 0; x < state.newPhotos.length; x++) {
            const file = state.newPhotos[x].originFileObj;
            datas.append("file", file);
          }
          const upload = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/upload`,
            datas
          );
          const updatedPic = note[0].photos?.gallery?.concat(upload.data.urls);
          if (upload.status === 200) {
            setState({
              ...state,
              newPhotos: [],
            });
          }

          const reqBody = {
            text: editNoteText !== "" ? editNoteText : note[0].text,
            photos: {
              gallery: updatedPic,
            },
          };

          const res: any = await updateNote(selectedNote, reqBody);
          if (res.status === 200) {
            setContentFetched(true);
          }
          setNoteText("");
          setSelectedTags("ADMIN");
          setSelectedNote("");
        };
        handleUpload();
      }
    }
  };

  const handleChange = (noteId: any, value: any, image: any) => {
    let notes = [...shootNotes];
    const remove = notes.map(
      (note) =>
        note._id === noteId &&
        note.photos.gallery?.filter((pic: any) => pic !== image)
    );
    const note = notes.filter((note) => note._id === noteId);
    const updatedPic = remove.filter((pic) => pic !== false);
    if (value !== "") {
      const reqBody = {
        text: value?.type === "click" ? note[0].text : value,
        photos: {
          gallery: updatedPic[0],
        },
      };
      updateNote(noteId, reqBody);
    }
    const deletePhotos = newPhotos.filter(
      (target: any) => target.url !== noteId
    );
    setState({
      ...state,
      newPhotos: deletePhotos,
    });
  };

  let handleUpload: any;
  let Photos: any;
  const handlePhotoChange = async (target: any) => {
    const newPhotos = [];
    for (let index = 0; index < target.length; index++) {
      const file = dataURLtoFile(target[index], "note-attachment" + Date.now());
      newPhotos.push({
        originFileObj: file,
        _id: existingPhotos.length + index + 1,
        order: existingPhotos.length + index + 1,
      });
    }
    Photos = newPhotos;
    setState({
      ...state,
      newPhotos: newPhotos,
    });
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const filetype = mime.substring(mime.lastIndexOf("/") + 1);
    return new File([u8arr], `${filename}.${filetype}`, { type: mime });
  }

  const handleNoteCreate = async () => {
    setCamera([]);
    setContentFetched(false);
    if (noteText === "") {
      return;
    }
    setTouched(false);
    const tags = () =>
      auth?.user?.access.includes("CLIENT")
        ? [...selectedTags, "CLIENT"]
        : selectedTags;
    if (Photos.length > 0) {
      handleUpload = async () => {
        let datas = new FormData();
        datas.append("path", `shoots/notes/high/${selectedShoot?.hsn}_`);
        for (let x = 0; x < Photos.length; x++) {
          const file = Photos[x].originFileObj;
          datas.append("file", file);
        }
        const upload = await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/upload`,
          datas
        );
        if (upload.status === 200) {
          setState({
            ...state,
            newPhotos: [],
          });

          const res: any = await createNote({
            text: noteText,
            photos: {
              gallery: upload.data.urls,
            },
            access: tags(),
            user: auth?.user?._id,
            entity_id: selectedShoot?._id,
            entity_type: "Shoot",
          });
          setNoteText("");
          setSelectedTags("ADMIN");
          if (res.status === 200) {
            setContentFetched(true);
          }
        }
      };
    } else {
      const res: any = await createNote({
        text: noteText,
        photos: [],
        access: tags(),
        user: auth?.user?._id,
        entity_id: selectedShoot?._id,
        entity_type: "Shoot",
      });
      setNoteText("");
      setSelectedTags("ADMIN");
      if (res.status === 200) {
        setContentFetched(true);
      }
    }
    if (Photos.length > 0) {
      handleUpload();
    }
  };

  const onInputChange = (e: any) => {
    setNoteText(e.target.value);
    if (noteText !== "") {
      setTouched(true);
    } else {
      setTouched(false);
    }
  };

  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const renderLoading = () => {
    return (
      <div>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={!contentFetched}
          message={"Please wait..."}
          duration={2000}
        />
      </div>
    );
  };

  const renderServices = (
    category: string,
    desc: string,
    code: string,
    key: string,
    amenities: any
  ) => {
    return (
      desc &&
      desc !== "Youtube Slideshow" &&
      desc !== "Flyer Design" && (
        <div key={key}>
          {category.includes("Interior/Exterior Photos") && (
            <IconDetail
              icon={<img src={PhotosIcon} alt="Photos icon" />}
              detail={desc}
            />
          )}
          {category.includes("Drone Photos and HD Video") &&
            !code.includes("SMV") && (
              <IconDetail
                icon={<img src={WalkthroughIcon} alt="Walkthrough icon" />}
                detail={desc}
              />
            )}
          {category.includes("Drone Photos and HD Video") &&
            code.includes("SMV") && (
              <IconDetail
                icon={<img src={YoutubeIcon} alt="Youtube icon" />}
                detail={desc}
              />
            )}
          {category.includes("Drone Photos") &&
            !category.includes("HD Video") && (
              <IconDetail
                icon={<img src={DroneIcon} alt="Drone icon" />}
                detail={desc}
              />
            )}
          {desc === "Dollhouse 3D Tour" && (
            <IconDetail
              icon={<img src={DollhouseIcon} alt="VR icon" />}
              detail="Dollhouse"
            />
          )}
          {desc === `Twilight Timeslot` && (
            <IconDetail
              icon={<img src={TwilightIcon} alt="Twilight icon" />}
              detail="Twilight"
            />
          )}
          {category.includes("Photo Turnaround") &&
            code !== "FREE" &&
            code !== "CFREE" &&
            code !== "MFREE" && (
              <IconDetail
                icon={<img src={ClockIcon} alt="Clock icon" />}
                detail={code}
              />
            )}
          {category.includes("Video Turnaround") &&
            code !== "CFREE" &&
            code !== "MFREE" && (
              <IconDetail
                icon={<img src={ClockIcon} alt="Clock icon" />}
                detail={code}
              />
            )}
          {category.includes(`Neigborhood Amenities`) && (
            <>
              <IconDetail
                icon={<img src={LocationIcon} alt="Location icon" />}
                detail={desc}
              />
              <span style={{ fontSize: 12, marginLeft: 45, color: "#0096FF" }}>
                Take 5 photos total including:
              </span>
              {amenityData?.map(
                (amenity: any) =>
                  amenities.includes(amenity._id) && (
                    <IonRow
                      style={{ marginLeft: 30, fontSize: "12px" }}
                      key={amenity?._id}
                    >
                      <IonCol size="4">
                        <span className="amenityInfo">{amenity.name}</span>
                      </IonCol>
                      <IonCol>
                        <span className="amenityAddress">{`${amenity.street}, ${amenity.city}, ${amenity.state} ${amenity.zip}`}</span>
                      </IonCol>
                    </IonRow>
                  )
              )}
            </>
          )}
        </div>
      )
    );
  };

  const renderDirections = () => {
    return (
      <IonGrid>
        <IonRow>
          <IonCol className="detail-address">
            <h4>
              {selectedShoot?.address?.apt !== ""
                ? `${selectedShoot?.address?.street.replace("null ", "")} ${
                    selectedShoot?.address?.apt
                      ? ` #${selectedShoot?.address?.apt}`
                      : ""
                  }, ${selectedShoot?.address?.city}, ${
                    selectedShoot?.address?.state
                  } ${selectedShoot?.address?.zip}`
                : selectedShoot?.address?.full.replace("null ", "")}
            </h4>{" "}
            <IonRow>
              <IonCol> HS# {selectedShoot?.hsn}</IonCol>
              <IonCol>
                <IonButton
                  size="small"
                  fill="outline"
                  expand="block"
                  style={{ float: "right" }}
                  onClick={() => onBrowserDirections()}
                >
                  Directions
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <Map
              width="100%"
              height={amenityData?.length > 0 ? "200px" : "300px"}
              centerLat={selectedShoot?.address?.geometry?.coordinates[1]}
              centerLng={selectedShoot?.address?.geometry?.coordinates[0]}
              defaultZoom={amenityData?.length > 0 ? 12 : 16}
              markerData={amenityData}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ShootTypeBadge
              shootType={selectedShoot?.booking_form?.shoot_type}
            />

            <IonChip>
              <IonLabel color={chipColor(selectedShoot?.status)}>
                {selectedShoot?.status}
              </IonLabel>
            </IonChip>
            {selectedShoot?.flexible && (
              <IonChip>
                <IonLabel color="danger">Flex</IonLabel>
              </IonChip>
            )}
            {selectedShoot?.reqs?.includes("DLX") && (
              <IonChip>
                <IonLabel color="tertiary">Deluxe</IonLabel>
              </IonChip>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const renderPinnedNotes = () => {
    return (
      <div className="notes pinned">
        {clientNotes.map((note: any) => (
          <Note
            currentNote={note}
            setShowPopover={setShowPopover}
            showActions={false}
            shootNotes={shootNotes}
            selectedShoot={selectedShoot}
            renderEditModal={(key: any) => renderEditModal(note, key)}
            key={note?._id}
            setContentFetched={setContentFetched}
          />
        ))}
      </div>
    );
  };

  const slideOpts = {
    zoom: {
      maxRatio: 3,
      toggle: true,
    },
  };

  const renderImages = () => {
    return (
      <>
        <div style={{ padding: 20 }} className="customImagegallery?">
          {selectedShoot.media.photos.gallery?.length > 0 &&
            selectedShoot.media.photos.gallery?.map((image: any) => (
              <div className="imageWithClose">
                <img src={image} alt={image} style={{ width: "100%" }} />
                <button
                  className="view"
                  onClick={() => (
                    setPreviewImage(true), setCurrentImage(image)
                  )}
                >
                  <IonIcon slot="icon-only" icon={eye} />
                </button>
              </div>
            ))}
        </div>
        <IonModal
          //cssClass="imagePreview"
          isOpen={previewImage}
          onDidDismiss={handleCancel}
        >
          <div>
            <IonSlides options={slideOpts}>
              <IonSlide className="swiper-zoom-container">
                <img
                  style={{ maxHeight: "70vh" }}
                  src={currentImage}
                  alt={currentImage}
                />
              </IonSlide>
            </IonSlides>
            <IonButton color="light" onClick={handleCancel} size="small">
              <IonIcon slot="icon-only" icon={closeOutline} />
              Close
            </IonButton>
          </div>
        </IonModal>
      </>
    );
  };
  const renderNotes = () => {
    return (
      <>
        {contentFetched ? (
          <div className="notes">
            {shootNotes.map((note: any) => (
              <Note
                currentNote={note}
                setShowPopover={setShowPopover}
                shootNotes={shootNotes}
                showActions={note?.user?._id === auth?.user?._id}
                selectedShoot={selectedShoot}
                setContentFetched={setContentFetched}
                renderEditModal={(key: any) => renderEditModal(note, key)}
                key={note?._id}
              />
            ))}
            <IonItem
              lines="full"
              style={{
                borderTop: "3px solid #d6e6f5",
              }}
            >
              <IonTextarea
                value={noteText}
                placeholder="Your note..."
                rows={1}
                required={true}
                onIonChange={(e: any) => onInputChange(e)}
              ></IonTextarea>

              <IonRow>
                <IonCol>
                  <IonImg>
                    {" "}
                    <IonIcon icon={addOutline} />
                  </IonImg>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    hidden
                    onChange={handlePhotoChange}
                  />
                </IonCol>
                <IonCol>
                  <IonButton shape="round" color="light" onClick={takePicture}>
                    <IonIcon icon={cameraOutline} />
                  </IonButton>
                </IonCol>
                <IonCol>
                  <div className="send-btn">
                    <IonButton
                      shape="round"
                      size="small"
                      color="light"
                      onClick={() => {
                        handlePhotoChange(camera);
                        handleNoteCreate();
                      }}
                      disabled={!touched}
                    >
                      <IonIcon slot="icon-only" icon={paperPlaneOutline} />
                    </IonButton>
                  </div>
                  <IonImg>
                    <IonRow className="p-2">
                      {state.newPhotos.map((image: any) => (
                        <IonCol key={image.url}>
                          <IonImg src={image.thumbUrl} alt="" />
                          <div>
                            <span>{image.filename}</span>
                            <span>
                              <IonButton
                                size="small"
                                onClick={() =>
                                  handleChange(undefined, undefined, image.url)
                                }
                              >
                                <IonIcon slot="icon-only" icon={closeOutline} />
                              </IonButton>
                            </span>
                          </div>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonImg>
                </IonCol>
              </IonRow>
            </IonItem>
            {camera.length > 0 && (
              <IonItem>
                <div className="customImageGallery selectedImage">
                  {camera.map((image: any) => {
                    return (
                      <div className="imageWithClose">
                        <img src={image} alt={image} />
                        <button
                          className="close"
                          onClick={() => imageRemove(image)}
                        >
                          <IonIcon slot="icon-only" icon={closeOutline} />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </IonItem>
            )}
            <IonItem lines="full">
              <IonLabel>Who should see this note?</IonLabel>
              <IonSelect
                value={noteAccess}
                multiple={true}
                cancelText="Cancel"
                okText="Okay"
                onIonChange={(e) => setNoteAccess(e.detail.value)}
              >
                <IonSelectOption value="CLIENT">Client</IonSelectOption>
                <IonSelectOption value="EDITOR">Editor</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
        ) : (
          <IonLoading
            cssClass="my-custom-class"
            isOpen={!contentFetched}
            message={"Please wait..."}
            duration={2000}
          />
        )}
      </>
    );
  };

  const renderDetails = () => {
    return (
      <>
        <LabelDetail
          label="Entry Method"
          detail={selectedShoot.booking_form.entry_method}
        />
        {selectedShoot.booking_form.entry_id && (
          <LabelDetail
            label="Entry ID:"
            detail={selectedShoot.booking_form.entry_id}
          />
        )}
        <LabelDetail
          label="Occupancy"
          detail={selectedShoot.booking_form.resi}
        />

        {/*<LabelDetail label="List Price." detail={selectedShoot.booking_form.list_price} /> */}
        <NumberFormat
          value={selectedShoot.booking_form.list_price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
          renderText={(value) => (
            <LabelDetail label="List Price" detail={value} />
          )}
        />

        {/*<LabelDetail label="Sqft." detail={selectedShoot.booking_form.sqft} /> */}
        <NumberFormat
          value={selectedShoot.booking_form.sqft}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => <LabelDetail label="Sqft" detail={value} />}
        />

        {selectedShoot.booking_form.drone_focus !== "" && (
          <LabelDetail
            label="Drone Focus"
            detail={selectedShoot.booking_form.drone_focus}
          />
        )}
      </>
    );
  };

  const renderContacts = () => {
    return (
      <>
        <LabelDetail
          label="Client Name (Primary)"
          detail={`${selectedShoot.client_name_first} ${selectedShoot.client_name_last}`}
        />
        <div className="label-detail">
          <div className="label">Phone</div>
          <div className="detail">
            <a href={`tel:${selectedShoot.client_phone}`}>
              {selectedShoot.client_phone}
            </a>
          </div>
        </div>
        <div className="label-detail">
          <div className="label">Email</div>
          <div className="detail">
            <a href={`mailto:${selectedShoot.client_email}`}>
              {selectedShoot.client_email}
            </a>
          </div>
        </div>
        <ContactButtons
          createActivity={createActivity}
          item={selectedShoot}
          clientTel={
            selectedShoot.booking_form.primary_contact?.phone === ""
              ? selectedShoot.booking_form.primary_contact?.phone
              : selectedShoot?.client_phone
          }
          clientEmail={
            selectedShoot.booking_form.primary_contact?.email === ""
              ? selectedShoot.booking_form.primary_contact?.email
              : selectedShoot.client_email
          }
          clientName={
            selectedShoot.booking_form.primary_contact?.client_name_first === ""
              ? selectedShoot.booking_form.primary_contact?.client_name_first
              : selectedShoot.client_name_first
          }
          photog={selectedShoot?.events?.photographer}
          hsn={selectedShoot?.hsn}
          address={selectedShoot?.address}
          auth={auth}
          position={position?.coords}
        />
        {selectedShoot?.booking_form?.secondary_contact &&
          selectedShoot?.booking_form?.secondary_contact?.name !== "" && (
            <>
              <div style={{ marginTop: 10 }}>
                <LabelDetail
                  label="Client Name (Seondary)"
                  detail={selectedShoot.booking_form.secondary_contact?.name}
                />
                <div className="label-detail">
                  <div className="label">Phone</div>
                  <div className="detail">
                    <a
                      href={`tel:${selectedShoot.booking_form.secondary_contact?.phone}`}
                    >
                      {selectedShoot.booking_form.secondary_contact?.phone}
                    </a>
                  </div>
                </div>
                <div className="label-detail">
                  <div className="label">Email</div>
                  <div className="detail">
                    <a
                      href={`mailto:${selectedShoot.booking_form.secondary_contact?.email}`}
                    >
                      {selectedShoot.booking_form.secondary_contact?.email}
                    </a>
                  </div>
                </div>
                <ContactButtons
                  createActivity={createActivity}
                  item={selectedShoot}
                  clientTel={
                    selectedShoot?.booking_form?.secondary_contact?.phone
                  }
                  clientEmail={
                    selectedShoot?.booking_form?.secondary_contact?.email
                  }
                  clientName={
                    selectedShoot?.booking_form?.secondary_contact?.name
                  }
                  photog={selectedShoot?.events?.photographer}
                  hsn={selectedShoot?.hsn}
                  address={selectedShoot?.address}
                  auth={auth}
                  position={position?.coords}
                />
              </div>
            </>
          )}
      </>
    );
  };

  const renderInclServices = () => {
    return (
      <>
        <div style={{ marginTop: "10px" }}>
          {selectedShoot.invoice?.map((inv: any, index: number) => (
            <div className="incl-services" key={index}>
              {index > 0 && (
                <span
                  style={{ float: "right", padding: 3, fontSize: "0.8rem" }}
                >{`Invoice #${index + 1}`}</span>
              )}
              {inv &&
                inv.products?.map((product: any) =>
                  renderServices(
                    product.category,
                    product.description,
                    product.code,
                    product._id,
                    product.amenities
                  )
                )}
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderToolbar = () => {
    return (
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {selectedShoot.address?.street.replace("null ", "")}
          </IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton routerLink={"/profile"}>
              <IonIcon slot="icon-only" icon={personOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    );
  };

  const renderHeaderActions = () => {
    return selectedShoot !== "reassigned" ? (
      <IonCol>
        <div className="header-actions">
          <div className="header-icon">
            <img src={ClockIcon} alt="clock icon" />
          </div>
          <div className="header-date-time">
            <div className="header-date">
              {moment(selectedShoot.events.start).format("MMM D, YYYY")}
            </div>
            <div className="header-time">
              {eventTimeFormat(
                selectedShoot.events.start,
                selectedShoot.events.end
              )}
            </div>
          </div>

          <div className="header-btn">
            {selectedShoot?.status === "Tentative" ? (
              <IonButton size="small" color="success" disabled={true}>
                Complete
              </IonButton>
            ) : (
              <>
                <CompleteShoot
                  setReason={setReason}
                  reason={reason}
                  setSubmitting={setSubmitting}
                  submitting={submitting}
                  isCompletedShoot={isCompletedShoot}
                  setIsCompletedShoot={setIsCompletedShoot}
                  completeHandler={completeHandler}
                  incompleteHandler={incompleteHandler}
                  setRequestReschedule={setRequestReschedule}
                  requestReschedule={requestReschedule}
                  setMarkAsUpsell={setMarkAsUpsell}
                  markAsUpsell={markAsUpsell}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </>
            )}
          </div>
        </div>
      </IonCol>
    ) : (
      ""
    );
  };

  const renderEditModal = (note: any, key: any) => {
    let notes = [...shootNotes];
    let selectedNote: any = [notes.find((note) => note._id === key)];
    return (
      <IonModal isOpen={showEditNoteModal}>
        <div className="modal-content">
          <div className="edit-note">
            <div className="modal-title">
              <span style={{ marginRight: 5 }}>
                <img src={NotesIcon} alt="Notes icon" width="22px" />
              </span>
              <h4>Edit Note</h4>
            </div>

            <IonTextarea
              value={editNoteText != null ? editNoteText : selectedNote.text}
              rows={2}
              required={true}
              onIonChange={(e) => setEditNoteText(e.detail.value!)}
            ></IonTextarea>

            <IonButton
              style={{ float: "right" }}
              size="small"
              onClick={() => (
                showImageModal(selectedNote._id), setShowEditNoteModal(false)
              )}
              fill="clear"
            >
              <IonIcon slot="icon-only" icon={cloudUploadOutline} />
            </IonButton>
            <IonModal isOpen={isModalVisible} onDidDismiss={handleCancel}>
              <div className="contentBody">
                <IonRow className="p-2">
                  {state.newPhotos.map((image: any) => (
                    <IonCol key={image.url}>
                      <IonImg src={image.thumbUrl} alt="" />
                      <div>
                        <span>{image.filename}</span>
                      </div>
                    </IonCol>
                  ))}
                </IonRow>
              </div>
              <div style={{ padding: "30px" }}>
                <IonRow className="pt-2">
                  <IonCol>
                    <IonButton onClick={handleClick} fill="clear">
                      <IonIcon slot="icon-only" icon={cloudUploadOutline} />
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={hiddenFileInput}
                        onChange={handlePhotoChange}
                      />
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      onClick={() => (
                        handlePhotoEdit(), setIsModalVisible(false)
                      )}
                      className="float-right"
                    >
                      Upload
                    </IonButton>
                  </IonCol>
                </IonRow>
              </div>
            </IonModal>
            <IonButton
              shape="round"
              onClick={() => handleUpdateNote(selectedNote._id)}
            >
              Save
            </IonButton>
            <IonButton
              color="primary"
              fill="outline"
              shape="round"
              onClick={() => {
                setShowEditNoteModal(false);
                setShowPopover(false);
              }}
            >
              Cancel
            </IonButton>
          </div>
        </div>
      </IonModal>
    );
  };

  return (
    <IonPage>
      {selectedShoot && selectedShoot?._id === selectedShootId ? (
        <React.Fragment>
          <CollapseHeader
            renderToolbar={renderToolbar}
            renderActions={renderHeaderActions}
          />
          <IonContent>
            {selectedShoot !== "reassigned" ? (
              <>
                <Accordion
                  title={<h4>Directions</h4>}
                  content={renderDirections()}
                  icon={<img src={LocationIcon} alt="Location" />}
                  expand={true}
                />
                <Accordion
                  title={<h4>Included Services</h4>}
                  content={renderInclServices()}
                  icon={<img src={ServicesIcon} alt="Services" />}
                  expand={true}
                />
                {clientNotes.length > 0 && (
                  <Accordion
                    title={<h4>Pinned Notes</h4>}
                    content={renderPinnedNotes()}
                    icon={
                      <IonIcon
                        slot="icon-only"
                        icon={informationCircleOutline}
                        style={{ color: "#28639D", fontSize: 26 }}
                      />
                    }
                    expand={true}
                  />
                )}
                {selectedShoot.media.photos?.gallery?.length > 0 &&
                  (selectedShoot.status.includes("Completed") ||
                    selectedShoot.status.includes("Tentative") ||
                    selectedShoot.status.includes("Reschedule") ||
                    selectedShoot.status.includes("Processing")) && (
                    <Accordion
                      title={<h4>Photos</h4>}
                      content={
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            padding: "10px",
                          }}
                        >
                          {/* <IonItem> */}
                          <IonButton onClick={() => onViewMediaOnline()}>
                            View Media Online
                          </IonButton>
                          <IonButton
                            slot="end"
                            onClick={() => onViewMediaOnDashboard()}
                          >
                            View on Dashboard
                          </IonButton>
                          {selectedShoot?.original_shoot && (
                            <IonButton
                              slot="end"
                              onClick={() =>
                                onViewOriginalShoot(
                                  selectedShoot.original_shoot?._id
                                )
                              }
                            >
                              Original Shoot
                            </IonButton>
                          )}
                          {selectedShoot?.rescheduled_shoot && (
                            <IonButton
                              slot="end"
                              onClick={() =>
                                onViewRescheduledShoot(
                                  selectedShoot?.rescheduled_shoot?._id
                                )
                              }
                            >
                              Rescheduled Shoot
                            </IonButton>
                          )}
                          {/* </IonItem> */}
                          {renderImages()}
                        </div>
                      }
                      icon={<img src={ServicesIcon} alt="Services" />}
                      expand={true}
                    />
                  )}
                <Accordion
                  title={<h4>Important Notes</h4>}
                  content={renderNotes()}
                  icon={<img src={NotesIcon} alt="Notes" />}
                  expand={true}
                />
                <Accordion
                  title={<h4>Property Details</h4>}
                  content={renderDetails()}
                  icon={<img src={DetailsIcon} alt="Property Details" />}
                  expand={true}
                />
                <Accordion
                  title={<h4>Contacts</h4>}
                  content={renderContacts()}
                  icon={<img src={ContactsIcon} alt="Contacts" />}
                  expand={true}
                />{" "}
              </>
            ) : (
              <p style={{ textAlign: "center" }}>Reassigned</p>
            )}
          </IonContent>
          <IonFooter translucent collapse="fade">
            <IonToolbar>
              <ContactButtons
                createActivity={createActivity}
                item={selectedShoot}
                clientTel={
                  selectedShoot.booking_form.primary_contact?.phone === ""
                    ? selectedShoot.booking_form.primary_contact?.phone
                    : selectedShoot?.client_phone
                }
                clientEmail={
                  selectedShoot.booking_form.primary_contact?.email === ""
                    ? selectedShoot.booking_form.primary_contact?.email
                    : selectedShoot.client_email
                }
                clientName={
                  selectedShoot.booking_form.primary_contact
                    ?.client_name_first === ""
                    ? selectedShoot.booking_form.primary_contact
                        ?.client_name_first
                    : selectedShoot.client_name_first
                }
                photog={selectedShoot?.events?.photographer}
                hsn={selectedShoot?.hsn}
                address={selectedShoot?.address}
                auth={auth}
                position={position?.coords}
              />
            </IonToolbar>
          </IonFooter>
        </React.Fragment>
      ) : (
        renderLoading()
      )}
      {selectedShoot !== "reassigned" ? (
        <>
          <IonToast
            isOpen={showSuccessMessage}
            onDidDismiss={() => setSuccessMessage(false)}
            message="Congrats! You completed the Shoot."
            duration={3000}
          />{" "}
        </>
      ) : (
        ""
      )}
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedShoot: state.selectedShoot,
    invoice: state.invoice,
    shootNotes: state.shootNotes,
    clientNotes: state.clientNotes,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  fetchShoot,
  fetchShootNotes,
  fetchClientNotes,
  fetchShootInvoice,
  updateShoot,
  shootCompletedByPhotographer,
  createNote,
  updateNotes,
  updateNote,
  deleteNote,
  emailChangeRequest,
  updateSelectedShoot,
  createActivity,
})(Detail);
