import React from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import { IonRouterOutlet } from "@ionic/react";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  path: string;
  authenticated: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  path,
  authenticated,
}) => {
  return (
    <IonRouterOutlet>
      <Route
        path={path}
        render={() =>
          authenticated ? <Component exact={true} /> : <Redirect to="/login" />
        }
      />
    </IonRouterOutlet>
  );
};

const mapStateToProps = (state: any) => ({
  authenticated: state.authenticated,
});

export default connect(mapStateToProps)(ProtectedRoute);
