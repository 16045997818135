import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {
  IonContent,
  IonPage,
  IonLoading,
  IonIcon,
  IonItem,
  IonLabel,
  IonItemDivider,
  IonCard,
  IonCardHeader,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonInput,
} from "@ionic/react";
import {
  fetchPhotographerShoots,
  storeShoots,
  updateSearchValue,
  setActiveTab,
  updatePhotographerShoots,
  loadMoreUpdate,
  savePageOffSet,
} from "../../../redux/actions";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { ellipsisHorizontal, search } from "ionicons/icons";

import PhotosIcon from "../../../assets/images/icons/Photos.svg";
import DroneIcon from "../../../assets/images/icons/Drone.svg";
import WalkthroughIcon from "../../../assets/images/icons/AerialVideo.svg";
import TwilightIcon from "../../../assets/images/icons/Twilight.svg";
import DollhouseIcon from "../../../assets/images/icons/3D.svg";
import YoutubeIcon from "../../../assets/images/icons/Youtube.svg";

import {
  eventTimeFormat,
  eventDateFormat,
  chipColor,
} from "../../../helpers/utils";

import "../style.css";
import LogoHeader from "../../../components/LogoHeader";
import CollapseHeader from "../../../components/CollapseHeader";
import EmptyState from "../../../components/EmptySate";
import socketIOClient from "socket.io-client";
import ShootTypeBadge from "../../../components/ShootTypeBadge";

interface shootProps {
  fetchPhotographerShoots: any;
  loadMoreUpdate: any;
  savePageOffSet: any;
  photographerShoots: any;
  countForLoadMore: any;
  auth: any;
  loading: boolean;
  error: any;
  updatePhotographerShoots: any;
  updateSearchValue: any;
  storeShoots: any;
  setActiveTab: any;
  activeTab: string;
  initialOffsetTop: number;
  searchedValue: string;
  invoice: any;
  completedShootsState: any;
}

const Photographer: React.FC<shootProps> = ({
  photographerShoots,
  countForLoadMore,
  fetchPhotographerShoots,
  loadMoreUpdate,
  savePageOffSet,
  auth,
  loading,
  updateSearchValue,
  storeShoots,
  updatePhotographerShoots,
  setActiveTab,
  activeTab,
  error,
  initialOffsetTop,
  searchedValue,
  invoice,
  completedShootsState,
}) => {
  const [upcomingShoots, setUpcomingShoots] = useState<Array<any>>([]);
  const [completedShoots, setCompletedShoots] = useState<Array<object>>([]);
  const [loaderShoots, setLoaderShoots] = useState<any>([]);
  const [searchedAddress, setSearchedAddress] = useState<any>("");
  const [loadMoreButton, setLoadMoreButton] = useState(true);
  const [total, setTotal] = useState<any>(countForLoadMore);
  // const [activeTab, setActiveTab] = useState("upcoming");
  const [phtgId, setPhtgId] = useState("");
  const [updatedShoot, setUpdatedShoot] = useState<any>(null);
  useEffect(() => {
    const photographerId = auth?.profile?._id;
    if (photographerId) {
      setPhtgId(photographerId);
      fetchPhotographerTodayShoots(photographerId);
      fetchPhotographerUpcomingShoots(photographerId);
      fetchCompletedShoots(photographerId);
    }
  }, [auth]);
  const [showLoading, setShowLoading] = useState(false);
  const [initialScroll, setInitialScroll] = useState(false);

  const handleClick = (index: any) => {
    const offsetTop = document?.getElementById("row-" + index)?.offsetTop;
    if (offsetTop) {
      savePageOffSet(offsetTop);
    }
  };
  useEffect(() => {
    if (searchedValue !== "") {
      if (invoice) {
        let tempString: string = `${invoice?.hsn}`;
        if (
          invoice?.address?.includes(`${searchedValue}`) ||
          invoice?.address.toLowerCase().includes(`${searchedValue}`) ||
          invoice?.address.toUpperCase().includes(`${searchedValue}`) ||
          invoice.hsn === Number(searchedValue) ||
          tempString.includes(`${searchedValue}`) ||
          tempString.includes(`${searchedValue}`)
        ) {
          if (searchedValue.length > 0 || searchedAddress.length > 0) {
            filterCheckHandler({ value: searchedValue });
          }
        }
      }
    }
  }, [searchedValue]);
  useEffect(() => {
    if (updatedShoot !== null) {
      checkShoots();
    }
  }, [updatedShoot, auth]);
  useEffect(() => {
    if (searchedAddress !== "") {
      updateSearchValue(searchedAddress === undefined ? "" : searchedAddress);
    }
  }, [searchedAddress]);
  useEffect(() => {
    let tempVar = 0;
    if (count < loaderShoots.length) {
      setLoadMoreButton(false);
    }
  }, [photographerShoots]);

  useEffect(() => {
    loadMoreUpdate(total);
  }, [total]);

  useEffect(() => {
    let socket: any;
    const photographerId = auth?.profile?._id;
    if (photographerId) {
      socket = socketIOClient.connect(process.env.REACT_APP_WS_URL as string, {
        transports: ["websocket"],
      });
      socket.emit("usageAcess", photographerId);
      socket.on("request", (data: any) => {
        console.log("Connected Successfully!.");
      });
      socket.on(`photographer-${photographerId}-shoots`, (data: any) => {
        setUpdatedShoot(data);
      });
    }
    // CLEAN UP THE EFFECT
    return () => {
      if (socket) {
        socket.disconnect(photographerId);
        console.log("Socket disconnected!");
      }
    };
  }, [auth]);
  let count = total;

  const loadMore = () => {
    let loadMoreShoots: Array<object> = [];

    if (searchedValue !== "") {
      let shootsToBeFiltered = completedShootsState.length
        ? completedShootsState
        : completedShoots;

      if (completedShootsState === completedShoots) {
        for (let i = count; i < count + 10; i++) {
          if (shootsToBeFiltered) {
            let tempString: string = `${shootsToBeFiltered[i]?.hsn}`;
            if (
              shootsToBeFiltered[i].address.street.includes(
                `${searchedValue}`
              ) ||
              shootsToBeFiltered[i].address.street
                .toLowerCase()
                .includes(`${searchedValue}`) ||
              shootsToBeFiltered[i].address.street
                .toUpperCase()
                .includes(`${searchedValue}`) ||
              shootsToBeFiltered[i].hsn === Number(searchedValue) ||
              tempString.includes(`${searchedValue}`) ||
              tempString.includes(`${searchedValue}`)
            ) {
              loadMoreShoots.push(shootsToBeFiltered[i]);
            }
          } else {
            break;
          }
        }
      } else {
        let tempVar = 0;
        for (let i = count; i < count + 10; i++) {
          if (!shootsToBeFiltered[i]) {
            tempVar = 1;
            break;
          } else {
            loadMoreShoots.push(shootsToBeFiltered[i]);
          }
        }
        if (tempVar === 1) {
          setLoadMoreButton(false);
        }
      }
      setLoaderShoots(loaderShoots.concat(loadMoreShoots));
      setTotal(count + 10);
    } else {
      setLoadMoreButton(true);
      for (let i = count; i < count + 10; i++) {
        if (completedShoots[i]) {
          loadMoreShoots.push(completedShoots[i]);
        } else {
          break;
        }
      }
      setLoaderShoots(loaderShoots.concat(loadMoreShoots));
      setTotal(count + 10);
    }
  };
  const checkShoots = () => {
    const today = moment();
    const photographerId = auth?.profile?._id;
    const photographerShootsCopy = _.cloneDeep(photographerShoots);
    const upcomingShootsCopy = _.cloneDeep(upcomingShoots);
    const completedShootsCopy = _.cloneDeep(completedShoots);
    const start = updatedShoot.events.start?.replace(".000Z", "");
    if (photographerId === updatedShoot?.events?.photographer?._id) {
      let todayReplace = false;
      let upcomingReplace = false;
      let completedReplace = false;
      photographerShoots.map((shoot: any, index: number) => {
        if (shoot._id === updatedShoot._id) {
          if (
            (updatedShoot.status === "Scheduled" ||
              updatedShoot.status === "Tentative") &&
            moment.utc(updatedShoot.events.start).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
          ) {
            photographerShootsCopy[index] = updatedShoot;
            todayReplace = true;
          } else {
            photographerShootsCopy.splice(index, 1);
          }
        }
      });
      if (!todayReplace && moment(start).isSame(today, "day")) {
        photographerShootsCopy.push(updatedShoot);
      }
      upcomingShoots.map((shoot: any, index: number) => {
        if (shoot._id === updatedShoot._id) {
          if (
            (updatedShoot.status === "Scheduled" ||
              updatedShoot.status === "Tentative") &&
            moment(updatedShoot.events.start).isBetween(
              moment().add(1, "day"),
              moment().add(7, "day"),
              "day",
              "[)"
            )
          ) {
            upcomingShootsCopy[index] = updatedShoot;
            upcomingReplace = true;
          } else {
            upcomingShootsCopy.splice(index, 1);
            upcomingReplace = true;
          }
        }
      });
      if (
        !upcomingReplace &&
        moment(updatedShoot.events.start).isBetween(
          moment().add(1, "day"),
          moment().add(7, "day"),
          "day",
          "[)"
        )
      ) {
        upcomingShootsCopy.push(updatedShoot);
      }

      completedShoots.map((shoot: any, index: number) => {
        if (shoot._id === updatedShoot._id) {
          if (updatedShoot.status === "Completed") {
            completedShootsCopy[index] = updatedShoot;
            completedReplace = true;
          } else if (updatedShoot.status != "Completed") {
            completedShootsCopy.splice(index, 1);
            completedReplace = true;
          }
        }
      });
      if (updatedShoot.status === "Completed" && !completedReplace) {
        completedShoots.push(updatedShoot);
      }
    } else {
      photographerShoots.map((shoot: any, index: any) => {
        if (shoot._id === updatedShoot._id) {
          photographerShootsCopy.splice(index, 1);
        }
      });
      upcomingShoots.map((shoot: any, index: any) => {
        if (shoot._id === updatedShoot._id) {
          upcomingShootsCopy.splice(index, 1);
        }
      });
      completedShoots.map((shoot: any, index: any) => {
        if (shoot._id === updatedShoot._id) {
          completedShootsCopy.splice(index, 1);
        }
      });
    }
    updatePhotographerShoots(photographerShootsCopy);
    setUpdatedShoot(null);
    setCompletedShoots(completedShootsCopy);
    setUpcomingShoots(upcomingShootsCopy);
  };
  const fetchPhotographerTodayShoots = (photographerId: string) => {
    const today = moment().format("YYYY-MM-DD");
    const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    const filters = `startDate=${today}&endDate=${tomorrow}&status=Completed:Processing:Scheduled:Tentative:Training`;
    fetchPhotographerShoots(photographerId, filters);
  };

  const fetchPhotographerUpcomingShoots = async (photographerId: string) => {
    setShowLoading(true);
    const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    const week = moment().add(7, "day").format("YYYY-MM-DD");
    const filters = `startDate=${tomorrow}&endDate=${week}&status=Scheduled:Tentative:Training`;
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/photographer/${photographerId}?${filters}`
      );
      setUpcomingShoots(res.data);
    } catch (err) {
      setShowLoading(false);
      console.log(err);
    }
  };
  const fetchCompletedShoots = async (photographerId: string) => {
    setShowLoading(true);
    try {
      const filters = "status=Completed:Processing:Postpone:Reschedule";
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/photographer/${photographerId}?${filters}&sortOrder=desc`
      );
      // const sortedShoots = res.data.sort(
      //   (a: any, b: any) =>
      //     convertISOStrToDate(b.events.start).getTime() -
      //     convertISOStrToDate(a.events.start).getTime()
      // );
      setCompletedShoots(res.data);
      if (searchedValue === "") {
        storeShoots(res.data);
        setLoaderShoots(
          res.data.slice(
            0,
            countForLoadMore === undefined
              ? total
              : countForLoadMore !== 10
              ? countForLoadMore
              : 10
          )
        );
      }
      loadMoreUpdate(countForLoadMore);
      setTotal(countForLoadMore);
      if (!initialScroll && activeTab === "completed") {
        let content = document.querySelector("ion-content");
        await content?.scrollToPoint(0, initialOffsetTop - 175, 1000);
        setInitialScroll(true);
        setShowLoading(false);
      }
    } catch (err) {
      setShowLoading(false);
      console.log(err);
    }
  };

  const getProducts = (invoices: any) => {
    const products = [];
    for (let i = 0; i < invoices?.length; i++) {
      for (let j = 0; j < invoices[i].products?.length; j++) {
        products.push(invoices[i].products[j]);
      }
    }
    return products;
  };
  const renderServiceIcon = (serviceType: string, item: any) => {
    const products = getProducts(item);
    return products.map((invoice: any, index: Number) => (
      <div className="service-icons" key={index.toString()}>
        {invoice.category.includes(`Interior/Exterior Photos`) && (
          <img src={PhotosIcon} alt="icon" />
        )}
        {invoice.category.includes(`Drone Photos and HD Video`) &&
          !invoice.code.includes("SMV") && (
            <img src={WalkthroughIcon} alt="icon" />
          )}
        {invoice.category.includes(`Drone Photos and HD Video`) &&
          invoice.code.includes("SMV") && <img src={YoutubeIcon} alt="icon" />}
        {invoice.description === `Dollhouse 3D Tour` && (
          <img src={DollhouseIcon} alt="icon" />
        )}
        {invoice.category.includes(`Drone Photos`) &&
          !invoice.category.includes("HD Video") && (
            <img src={DroneIcon} alt="icon" />
          )}
        {invoice.description === "Twilight Timeslot" && (
          <img src={TwilightIcon} alt="icon" />
        )}
        {(invoice.code === "SD" ||
          invoice.code === "CSD" ||
          invoice.code === "MSD") && (
          <div
            style={{
              color: "#289d74",
              fontSize: "1.25rem",
              fontWeight: 500,
            }}
          >
            SD
          </div>
        )}
        {(invoice.code === "4HR" ||
          invoice.code === "C4H!" ||
          invoice.code === "M4H!") && (
          <div
            style={{
              color: "#9d2863",
              fontSize: "1.25rem",
              fontWeight: 500,
            }}
          >
            4HR
          </div>
        )}
      </div>
    ));
  };
  const filterCheckHandler = (value: any) => {
    if (value.value === "") {
      setSearchedAddress("");
      setLoaderShoots(
        completedShoots.slice(
          0,
          countForLoadMore === undefined
            ? total
            : countForLoadMore !== 10
            ? countForLoadMore
            : 10
        )
      );
    } else {
      let filterdShoot: any = [];
      let shootsToBeFiltered = completedShoots.length ? completedShoots : [];
      if (shootsToBeFiltered.length > 0) {
        shootsToBeFiltered.map((shootToBeFiltered: any) => {
          let tempString: string = `${shootToBeFiltered?.hsn}`;
          if (
            shootToBeFiltered.address.street.includes(`${value.value}`) ||
            shootToBeFiltered.address.street
              .toLowerCase()
              .includes(`${value.value}`) ||
            shootToBeFiltered.address.street
              .toUpperCase()
              .includes(`${value.value}`) ||
            shootToBeFiltered.hsn === Number(value.value) ||
            tempString.includes(`${searchedValue}`) ||
            tempString.includes(`${searchedValue}`)
          ) {
            filterdShoot.push(shootToBeFiltered);
          }
        });
      } else {
        filterdShoot = completedShootsState;
      }

      if (filterdShoot.length) {
        storeShoots(filterdShoot);
        setLoaderShoots(
          filterdShoot.slice(0, countForLoadMore ? countForLoadMore : 10)
        );
      } else {
        setLoaderShoots([]);
      }
      setSearchedAddress(value.value);
    }
  };
  const renderHeaderActions = () => {
    return (
      <IonCol>
        <div className="page-tabs">
          <IonSegment
            mode="md"
            value={activeTab}
            onIonChange={(e) => onTabChanged(e.detail.value)}
          >
            <IonSegmentButton value="upcoming">Upcoming</IonSegmentButton>
            <IonSegmentButton value="completed">Completed</IonSegmentButton>
          </IonSegment>
        </div>
      </IonCol>
    );
  };

  const renderLoading = () => {
    return (
      <div>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={2000}
        />
      </div>
    );
  };

  const onTabChanged = (selected: any) => {
    setActiveTab(selected);
    console.log("selected ", selected);
    console.log("activeTab ", activeTab);
  };

  const renderToolbar = () => {
    return <LogoHeader />;
  };
  const convertISOStrToDate = (ISODateStr: any) => {
    const localDateStr =
      ISODateStr?.split("T")[0] +
      " " +
      ISODateStr?.split("T")[1]?.split(".")[0];
    return new Date(localDateStr);
  };
  const renderActive = (tab: string) => {
    return tab === "upcomming" ? (
      <div>
        {photographerShoots.length === 0 && upcomingShoots.length === 0 && (
          <EmptyState />
        )}

        {photographerShoots.length > 0 && (
          <div className="card-section">
            <IonItemDivider>
              <IonLabel>Today, {moment().format("MMM D")}</IonLabel>
            </IonItemDivider>
            {photographerShoots
              .filter(
                (shoot: any) =>
                  moment.utc(shoot.events.start).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
              )
              .sort(
                (a: any, b: any) =>
                  convertISOStrToDate(a.events.start).getTime() -
                  convertISOStrToDate(b.events.start).getTime()
              )
              .map((shoot: any) => (
                <IonCard key={shoot._id}>
                  <IonItem lines="full" routerLink={`/detail/${shoot._id}`}>
                    <IonIcon icon={ellipsisHorizontal} slot="end" />
                    <IonCardHeader>
                      <h4>
                        {eventTimeFormat(
                          shoot?.events?.start,
                          shoot?.events?.end
                        )}{" "}
                        {shoot?.flexible && (
                          <span
                            style={{
                              marginLeft: 10,
                              padding: "2px 10px",
                              background: "#f9c3c39e",
                              color: "maroon",
                              borderRadius: 10,
                              fontSize: "12px",
                            }}
                          >
                            Flex
                          </span>
                        )}
                        {shoot?.reqs?.includes("DLX") && (
                          <span
                            style={{
                              marginLeft: 10,
                              padding: "2px 10px",
                              background: "#f9f0ff",
                              color: "#531dab",
                              borderRadius: 10,
                              fontSize: "12px",
                              borderColor: "#d3adf7",
                            }}
                          >
                            Deluxe
                          </span>
                        )}
                        <ShootTypeBadge
                          shootType={shoot.booking_form.shoot_type}
                        />
                      </h4>
                      <h3>
                        {shoot.address?.street.replace("null ", "")}{" "}
                        {shoot.address?.apt ? ` #${shoot.address?.apt}` : ""}
                      </h3>
                    </IonCardHeader>
                  </IonItem>
                  <IonItem lines="full">
                    {renderServiceIcon(
                      shoot?.booking_form?.shoot_type || "Residential",
                      shoot.invoice
                    )}
                    <span slot="end" className="meta-data">
                      <IonChip>
                        <IonLabel color={chipColor(shoot.status)}>
                          {shoot.status}
                        </IonLabel>
                      </IonChip>
                    </span>
                  </IonItem>
                </IonCard>
              ))}
          </div>
        )}
        {upcomingShoots.length > 0 && (
          <div className="card-section">
            <IonItemDivider>
              <IonLabel>Upcoming</IonLabel>
            </IonItemDivider>
            {upcomingShoots
              .sort(
                (a, b) =>
                  convertISOStrToDate(a.events.start).getTime() -
                  convertISOStrToDate(b.events.start).getTime()
              )
              .map((shoot: any) => (
                <IonCard key={shoot._id}>
                  <IonItem lines="full" routerLink={`/detail/${shoot._id}`}>
                    <IonIcon icon={ellipsisHorizontal} slot="end" />
                    <IonCardHeader>
                      <h4>
                        {eventDateFormat(shoot.events.start, shoot.events.end)}{" "}
                        {shoot?.flexible && (
                          <span
                            style={{
                              marginLeft: 10,
                              padding: "2px 10px",
                              background: "#f9c3c39e",
                              color: "maroon",
                              borderRadius: 10,
                              fontSize: "12px",
                            }}
                          >
                            Flex
                          </span>
                        )}
                        <ShootTypeBadge
                          shootType={shoot.booking_form.shoot_type}
                        />
                      </h4>
                      <h3 style={{ display: "flex", alignItems: "center" }}>
                        {shoot.address?.street.replace("null ", "")}{" "}
                        {shoot.address?.apt ? shoot.address?.apt : ""}
                      </h3>
                    </IonCardHeader>
                  </IonItem>
                  <IonItem lines="full">
                    {renderServiceIcon(
                      shoot?.booking_form?.shoot_type || "Residential",
                      shoot.invoice
                    )}
                    <span slot="end" className="meta-data">
                      <IonChip>
                        <IonLabel color={chipColor(shoot.status)}>
                          {shoot.status}
                        </IonLabel>
                      </IonChip>
                    </span>
                  </IonItem>
                </IonCard>
              ))}
          </div>
        )}
      </div>
    ) : (
      <div>
        <IonGrid style={{ marginLeft: "10px", marginBottom: "-10px" }}>
          <IonRow>
            <IonCol size="12">
              <IonInput
                type="text"
                placeholder="Search by address"
                value={searchedAddress}
                clearInput={true}
                onIonChange={(e: any) => {
                  filterCheckHandler(e.detail);
                }}
              >
                <IonIcon color="white" icon={search} />
              </IonInput>
            </IonCol>
          </IonRow>
        </IonGrid>
        {completedShoots.length === 0 && completedShoots.length === 0 && (
          <EmptyState />
        )}
        {loaderShoots.length > 0 && (
          <div className="card-section">
            {loaderShoots.map((shoot: any, index: number) => {
              return (
                <IonCard
                  key={shoot?._id}
                  id={"row-" + index}
                  onClick={() => handleClick(index)}
                >
                  <IonItem lines="full" routerLink={`/detail/${shoot._id}`}>
                    <IonIcon icon={ellipsisHorizontal} slot="end" />
                    <IonCardHeader>
                      <h4>
                        {eventDateFormat(
                          shoot.events?.start,
                          shoot.events?.end
                        )}
                      </h4>
                      <h3>{shoot.address?.street?.replace("null ", "")}</h3>
                    </IonCardHeader>
                  </IonItem>
                  <IonItem lines="full">
                    {renderServiceIcon(
                      shoot?.booking_form?.shoot_type || "Residential",
                      shoot.invoice
                    )}
                    <span slot="end" className="meta-data">
                      <IonChip>
                        <IonLabel color={chipColor(shoot.status)}>
                          {shoot.status}
                        </IonLabel>
                      </IonChip>
                    </span>
                  </IonItem>
                </IonCard>
              );
            })}
          </div>
        )}
        {loaderShoots.length !== completedShoots.length &&
          loaderShoots.length > 9 &&
          loadMoreButton && (
            <div className="load-more-button">
              <button onClick={loadMore}>Load More</button>
            </div>
          )}
      </div>
    );
  };

  return (
    <IonPage>
      <CollapseHeader
        renderToolbar={renderToolbar}
        renderActions={renderHeaderActions}
      />
      <IonContent>
        {activeTab === "upcoming" && renderActive("upcomming")}
        {activeTab === "completed" && renderActive("completed")}
        {showLoading && renderLoading()}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    photographerShoots: state.photographerShoots,
    auth: state.auth,
    activeTab: state.activeTab,
    countForLoadMore: state.countForLoadMore,
    initialOffsetTop: state.offsetTop,
    searchedValue: state.searchedValue,
    invoice: state.invoice,
    completedShootsState: state.completedShoots,
  };
};

export default connect(mapStateToProps, {
  fetchPhotographerShoots,
  updateSearchValue,
  storeShoots,
  loadMoreUpdate,
  savePageOffSet,
  updatePhotographerShoots,
  setActiveTab,
})(Photographer);
