import { get, set, remove } from "../helpers/storage";
import {
  FETCH_PHOTOG_SHOOTS_SUCCESS,
  FETCH_PHOTOG_SHOOTS_FAIL,
  FETCH_SHOOT_SUCCESS,
  FETCH_SHOOT_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  UPDATE_SEARCH_VALUE,
  LOGOUT_USER,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  FETCH_DRIVING_DISTANCE,
  FETCH_CLIENT_NOTES,
  FETCH_CLIENT_NOTES_FAIL,
  FETCH_SHOOT_NOTES,
  FETCH_SHOOT_NOTES_FAIL,
  FETCH_SHOOT_INVOICES_FAIL,
  FETCH_SHOOT_INVOICES,
  UPDATE_SHOOT,
  LOAD_MORE_UPDATE,
  SAVE_PAGE_OFFSET,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAIL,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  DELETE_NOTE,
  REGISTER_SUCCESS,
  UPDATE_PHOTOG_SHOOTS,
  UPDATE_SELECTED_SHOOT,
  UPDATE_SHOOT_NOTES,
  SET_ACTIVE_TAB,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAIL,
  COMPLETED_SHOOTS_STORED,
} from "./types";

const initialState = {
  token: get("hs-token"),
  loading: true,
  searchedValue: "",
  completedShoots: [],
  invoice: null,
  photographerShoots: [],
  shootNotes: <any>[],
  clientNotes: <any>[],
  error: null,
  auth: null,
  authenticated: null,
  activeTab: "upcoming",
  countForLoadMore: 10,
};

export default function reducer(state = initialState, action: any): any {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SELECTED_SHOOT:
      return {
        ...state,
        selectedShoot: payload,
      };
    case LOAD_MORE_UPDATE:
      return {
        ...state,
        countForLoadMore: payload,
      };
    case SAVE_PAGE_OFFSET:
      return {
        ...state,
        offsetTop: payload,
      };
    case USER_LOADED:
      return {
        ...state,
        authenticated: true,
        loading: false,
        auth: payload,
      };
    case UPDATE_SHOOT_NOTES:
      return {
        ...state,
        shootNotes: payload,
      };
    case REGISTER_SUCCESS:
      set("hs-token", payload.token);
      return {
        ...state,
        authenticated: true,
        loading: false,
        auth: { user: payload.user, profile: payload.photographer },
      };
    case AUTH_ERROR:
      return { ...state, error: payload, loading: false };
    case COMPLETED_SHOOTS_STORED:
      return { ...state, completedShoots: payload };
    case UPDATE_SEARCH_VALUE:
      return { ...state, searchedValue: payload };
    case FETCH_DRIVING_DISTANCE:
      return { ...state, drivingDistance: payload, loading: false };
    case FETCH_PHOTOG_SHOOTS_SUCCESS:
      return { ...state, photographerShoots: payload, loading: false };
    case FETCH_SHOOT_SUCCESS:
      return { ...state, selectedShoot: payload, loading: false };
    case UPDATE_SHOOT:
      return {
        ...state,
        selectedShoot: payload,
        photographerShoots: state.photographerShoots.map((shoot: any) =>
          shoot._id === payload._id ? payload : shoot
        ),
        loading: false,
      };
    case FETCH_SHOOT_NOTES:
      return { ...state, shootNotes: payload, loading: false };
    case FETCH_CLIENT_NOTES:
      return { ...state, clientNotes: payload, loading: false };
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        shootNotes: [...state.shootNotes, payload],
        loading: false,
      };
    case CREATE_NOTE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_NOTE_SUCCESS:
      return Object.assign(
        {},
        {
          ...state,
          shootNotes: state.shootNotes.map((note: any) =>
            note._id === payload._id ? payload : note
          ),
          loading: false,
        }
      );
    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      return Object.assign(
        {},
        {
          ...state,
          auth: payload,
          loading: false,
        }
      );
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_NOTE:
      return {
        ...state,
        shootNotes: state.shootNotes.filter(
          (note: any) => note._id !== payload
        ),

        isLoading: false,
      };
    case FETCH_SHOOT_INVOICES:
      return { ...state, invoice: payload, loading: false };
    case FETCH_PHOTOG_SHOOTS_FAIL:
    case FETCH_SHOOT_NOTES_FAIL:
    case FETCH_CLIENT_NOTES_FAIL:
    case FETCH_SHOOT_FAIL:
    case FETCH_SHOOT_INVOICES_FAIL:
    case CREATE_ACTIVITY_FAIL:
      return { ...state, error: payload, loading: false };
    case LOGIN_SUCCESS:
      set("hs-token", payload.token);
      return {
        ...state,
        ...payload,
        authenticated: true,
        error: null,
        loading: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        ...payload,
        token: null,
        auth: null,
        authenticated: false,
        loading: false,
      };
    case SOCIAL_LOGIN_SUCCESS:
      set("hs-token", payload.token);
      return {
        ...state,
        ...payload,
        authenticated: true,
        error: null,
        loading: false,
      };
    case SOCIAL_LOGIN_FAIL:
      return {
        ...state,
        ...payload,
        token: null,
        auth: null,
        authenticated: false,
        loading: false,
      };
    case LOGOUT_USER:
      remove("hs-token");

      return {
        ...state,
        token: null,
        auth: null,
        authenticated: false,
        loading: false,
      };
    case UPDATE_PHOTOG_SHOOTS:
      return {
        ...state,
        photographerShoots: payload,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
