import React from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route, Redirect, Switch } from "react-router";
import Login from "../pages/Login";
import Register from "../pages/register/Register";
import Dashboard from "../pages/dashboard/";
import ProtectedRoute from "../components/ProtectedRoute";
import Profile from "../pages/Profile";
import Help from "../pages/Help";
import Detail from "../pages/dashboard/components/Detail";
import ForgetPassword from "../pages/forget-password/ForgetPassword";
import ResetPassword from "../pages/reset-password/ResetPassword";

const TabRoot: React.FC = () => {
  return (
    <IonRouterOutlet>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/detail/:id" component={Detail} />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <Route path="/profile" component={Profile} />
        <Route path="/help" component={Help} />
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
      </Switch>
    </IonRouterOutlet>
  );
};

export default TabRoot;
