import axios from "axios";
import { get, set } from "../helpers/storage";
import {
  FETCH_PHOTOG_SHOOTS_SUCCESS,
  // FETCH_PHOTOG_DIST_SUCCESS,
  SAVE_PAGE_OFFSET,
  LOAD_MORE_UPDATE,
  FETCH_PHOTOG_SHOOTS_FAIL,
  FETCH_SHOOT_SUCCESS,
  FETCH_SHOOT_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT_USER,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  FETCH_DRIVING_DISTANCE,
  FETCH_SHOOT_NOTES,
  FETCH_CLIENT_NOTES,
  FETCH_CLIENT_NOTES_FAIL,
  FETCH_SHOOT_INVOICES,
  FETCH_SHOOT_NOTES_FAIL,
  FETCH_SHOOT_INVOICES_FAIL,
  UPDATE_SHOOT,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAIL,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  DELETE_NOTE,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_PHOTOG_SHOOTS,
  UPDATE_SELECTED_SHOOT,
  UPDATE_SHOOT_NOTES,
  SET_ACTIVE_TAB,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAIL,
  UPDATE_SEARCH_VALUE,
  COMPLETED_SHOOTS_STORED,
} from "./types";
import setAuthToken from "../helpers/setAuthToken";

export const fetchPhotographerShoots =
  (photographerId: string, filters: string) => async (dispatch: any) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/photographer/${photographerId}?${filters}`
      );
      dispatch({
        type: FETCH_PHOTOG_SHOOTS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_PHOTOG_SHOOTS_FAIL, payload: err.msg });
    }
  };
export const storeShoots = (completedShoots: any) => (dispatch: any) => {
  if (completedShoots.length > 0) {
    dispatch({
      type: COMPLETED_SHOOTS_STORED,
      payload: completedShoots,
    });
  }
};

export const updateSearchValue =
  (updatedSearchValue: any) => (dispatch: any) => {
    if (updatedSearchValue !== "") {
      dispatch({
        type: UPDATE_SEARCH_VALUE,
        payload: updatedSearchValue,
      });
    }
  };

export const loadMoreUpdate = (count: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: LOAD_MORE_UPDATE,
      payload: count,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: LOAD_MORE_UPDATE, payload: err.msg });
  }
};

export const savePageOffSet = (offsetTop: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SAVE_PAGE_OFFSET,
      payload: offsetTop,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: FETCH_PHOTOG_SHOOTS_FAIL, payload: err.msg });
  }
};

export const fetchDistanceArrayAndTotal =
  (id: string, addressArray: any) => async (dispatch: any) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/photographer-distance/${id}`,
        { params: { address: addressArray } }
      );
      return { data: res, success: true };
    } catch (err) {
      console.log(err);
      return { error: err, success: false };
    }
  };
export const fetchShoot = (shootId: string) => async (dispatch: any) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/${shootId}`
    );
    dispatch({
      type: FETCH_SHOOT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: FETCH_SHOOT_FAIL, payload: err.msg });
  }
};

export const updateSelectedShoot = (updatedShoot: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_SELECTED_SHOOT,
    payload: updatedShoot,
  });
};
export const updateNotes = (notes: any) => (dispatch: any) => {
  dispatch({
    type: UPDATE_SHOOT_NOTES,
    payload: notes,
  });
};
export const fetchDrivingDistance =
  (
    org_latitude: string,
    org_longitude: string,
    dest_latitude: string,
    dest_longitude: string
  ) =>
  async (dispatch: any) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=${org_latitude},${org_longitude}&destinations=${dest_latitude},${dest_longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`,
        config
      );

      const { distance, duration } = res.data.rows[0].elements[0];

      const drivingDistance = {
        distance,
        duration,
      };
      dispatch({
        type: FETCH_DRIVING_DISTANCE,
        payload: drivingDistance,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_SHOOT_FAIL, payload: err.msg });
    }
  };

export const loginUser =
  (email: string, password: string) => async (dispatch: any) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      password,
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/auth`,
        body,
        config
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: { error: err.response },
      });
      return err.response;
    }
  };

// Load current user
export const loadCurrentUser = () => async (dispatch: any) => {
  let config;

  const userToken = await get("hs-token");

  if (userToken) {
    setAuthToken(userToken);
    config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": userToken,
      },
    };
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/users/me`,
      config
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: AUTH_ERROR, payload: err.msg });
  }
};

export const fetchShootNotes =
  (entityId: string, query: string) => async (dispatch: any) => {
    let config;
    const userToken = await get("hs-token");
    if (userToken) {
      setAuthToken(userToken);
      config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": userToken,
        },
      };
    }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/notes/entity/${entityId}?access=${
          query ? query : null
        }`,
        config
      );
      dispatch({
        type: FETCH_SHOOT_NOTES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_SHOOT_NOTES_FAIL,
        payload: { error: err.response },
      });
      console.log(err);
    }
  };

export const fetchClientNotes =
  (clientId: string, query: string) => async (dispatch: any) => {
    let config;
    const userToken = await get("hs-token");
    if (userToken) {
      setAuthToken(userToken);
      config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": userToken,
        },
      };
    }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/notes/entity/${clientId}?access=${
          query ? query : null
        }`,
        config
      );
      dispatch({
        type: FETCH_CLIENT_NOTES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_CLIENT_NOTES_FAIL,
        payload: { error: err.response },
      });
      console.log(err);
    }
  };

export const fetchShootInvoice = (shootId: string) => async (dispatch: any) => {
  let config;

  const userToken = await get("hs-token");

  if (userToken) {
    setAuthToken(userToken);
    config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": userToken,
      },
    };
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/invoices/shoot/${shootId}`,
      config
    );
    dispatch({
      type: FETCH_SHOOT_INVOICES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_SHOOT_INVOICES_FAIL,
      payload: { error: err.response },
    });
    console.log(err);
  }
};

export const shootCompletedByPhotographer =
  (
    id: string,
    formData: any,
    reason: any,
    requestReschedule: boolean,
    markAsUpsell: boolean
  ) =>
  async (dispatch: any) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ROOT_URL}/shoots/payment/pay`,
        { id, formData, reason, requestReschedule, markAsUpsell }
      );
      if (response.status === 200) {
        dispatch({
          type: UPDATE_SHOOT,
          payload: response.data,
        });
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };

export const updateShoot =
  (id: string, formData: any) => async (dispatch: any) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ROOT_URL}/shoots/${id}`,
        formData
      );
      if (response.status === 200) {
        dispatch({
          type: UPDATE_SHOOT,
          payload: response.data,
        });
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };

export const createNote = (formData: any) => async (dispatch: any) => {
  let config;
  const userToken = await get("hs-token");
  if (userToken) {
    setAuthToken(userToken);
    config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": userToken,
      },
    };
  }

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notes`,
      formData,
      config
    );

    dispatch({
      type: CREATE_NOTE_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    dispatch({
      type: CREATE_NOTE_FAIL,
    });
    console.log(err);
  }
};

export const updateNote =
  (id: string, formData: any) => async (dispatch: any) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ROOT_URL}/notes/${id}`,
        formData
      );
      dispatch({
        type: UPDATE_NOTE_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (err) {
      dispatch({
        type: UPDATE_NOTE_FAIL,
      });
      return {
        data: null,
        error: err.response,
      };
    }
  };

export const deleteNote = (id: string) => async (dispatch: any) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/notes/${id}`
    );

    dispatch({
      type: DELETE_NOTE,
      payload: id,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updatePhotogProfile =
  (id: string, formData: any) => async (dispatch: any) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ROOT_URL}/photographers/profile/${id}`,
        formData
      );
      if (response.status === 200) {
        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: response.data,
        });
      }
      return { data: response, error: null };
    } catch (err) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
      });
      return {
        data: null,
        error: err.response,
      };
    }
  };

export const emailChangeRequest = (emailData: any) => async (dispatch: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notifications/admin-change-request`,
      emailData
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const socialLogin =
  (user: any, token: string) => async (dispatch: any) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ user, token });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/auth/social`,
        body,
        config
      );

      dispatch({
        type: SOCIAL_LOGIN_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: SOCIAL_LOGIN_FAIL,
        payload: { error: err.response },
      });
    }
  };

export const registerUser = (reqBody: any) => async (dispatch: any) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    name: reqBody.name,
    email: reqBody.email,
    password: reqBody.createPassword,
    access: ["PHOTOGRAPHER"],
    phtg_id: reqBody.photog_id,
    entryPoint: "Photographer",
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/users`,
      body,
      config
    );
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    //return { data: res.data, error: null };
    // dispatch(loginUser());
  } catch (err) {
    console.log(err);
    dispatch({
      type: REGISTER_FAIL,
      payload: {
        error: "Something went wrong!",
      }, // TODO: Handle error
    });
    // return { data: null, error: err.message };
  }
};

export const forgotPassword = (body: any) => async (dispatch: any) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/auth/forgot-password`,
      body,
      config
    );

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: res.data,
    });

    return {
      data: { status: true },
      error: null,
    };
  } catch (err) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: { error: err.response },
    });

    return {
      data: null,
      error: err.response,
    };
  }
};

export const resetPassword =
  ({ password, confirmPassword, token }: any) =>
  async (dispatch: any) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ password, token });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/auth/reset-password`,
        body,
        config
      );

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data,
      });

      return {
        data: { status: true },
        error: null,
      };
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: { error: err.response },
      });

      return {
        data: null,
        error: err.response,
      };
    }
  };

export const logoutUser = () => async (dispatch: any) => {
  dispatch({
    type: LOGOUT_USER,
  });
};

export const fetchAmenityByIds = async (ids: Array<any>) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/amenity/ids?id=${ids.join(",")}`
    );
    return { data: res.data, error: undefined };
  } catch (err) {
    console.log(err.message);
    return { data: null, error: err.message };
  }
};

export const updatePhotographerShoots =
  (photographerShoots: any) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_PHOTOG_SHOOTS,
      payload: photographerShoots,
    });
  };

export const setActiveTab = (activeTab: any) => async (dispatch: any) => {
  dispatch({
    type: SET_ACTIVE_TAB,
    payload: activeTab,
  });
};

export const createActivity =
  (shoot: any, text: string, userId: string) => async (dispatch: any) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        user: userId,
        text,
        entity_id: shoot._id,
        entity_type: "Photographer",
        geometry: {
          type: "Point",
          coordinates: [
            shoot.currentLocation.longitude,
            shoot.currentLocation.latitude,
          ],
        },
        _created_at: new Date(),
      };
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/activities`,
        body,
        config
      );
      dispatch({
        type: CREATE_ACTIVITY_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: CREATE_ACTIVITY_FAIL,
        payload: { error: err.response },
      });
    }
  };
