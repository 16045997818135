import React, { useState } from "react";
import { connect } from "react-redux";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonButton,
  IonModal,
  IonList,
  IonInput,
  IonLabel,
  IonBackButton,
  IonButtons,
  IonItemDivider,
  IonIcon,
  IonToast,
  IonText,
  IonAvatar,
  useIonAlert,
} from "@ionic/react";
import { logoutUser, updatePhotogProfile } from "../redux/actions";
import IconDetail from "../components/IconDetail";
import "./Profile.css";

import LocationIcon from "../assets/images/icons/Address.svg";
import PhoneIcon from "../assets/images/icons/Phone.svg";
import MailIcon from "../assets/images/icons/Mail.svg";
import { lockClosedOutline, personCircleOutline } from "ionicons/icons";
import PayBreakdown from "../components/PayBreakdown";
import socketIOClient from "socket.io-client";
interface profileProps {
  auth: any;
  loading: boolean;
  logoutUser: any;
  updatePhotogProfile: any;
  history: any;
}

const Profile: React.FC<profileProps> = ({
  auth,
  loading,
  logoutUser,
  updatePhotogProfile,
  history,
}) => {
  const [presentAlert] = useIonAlert();
  const [showModal, setShowModal] = useState(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [passwordError, setPasswordError] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [formData, setFormData] = useState<any>({
    user: auth?.user?._id,
    name: auth?.profile?.name,
    home_address: auth?.profile?.home_address,
    phone: auth?.profile?.phone,
    email: auth?.user?.email,
    oldPass: "",
    newPass: "",
    cnfPass: "",
  });

  const renderDefaultAvatar = (baseColor: string) => {
    return (
      <svg
        width="95"
        height="95"
        viewBox="0 0 95 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="94"
          height="94"
          rx="47"
          fill="#F3F7FF"
          stroke={baseColor}
          strokeDasharray="3 3"
        />
        <path
          d="M66.8661 34.0912H59.577L57.8404 29.2781C57.7204 28.9487 57.5006 28.664 57.2109 28.4629C56.9212 28.2618 56.5758 28.154 56.2218 28.1543H39.7783C39.0547 28.1543 38.4062 28.6049 38.165 29.2781L36.4231 34.0912H29.1339C26.765 34.0912 24.8462 35.9889 24.8462 38.3318V62.5034C24.8462 64.8464 26.765 66.744 29.1339 66.744H66.8661C69.2351 66.744 71.1539 64.8464 71.1539 62.5034V38.3318C71.1539 35.9889 69.2351 34.0912 66.8661 34.0912ZM67.2949 62.5034C67.2949 62.7366 67.102 62.9275 66.8661 62.9275H29.1339C28.8981 62.9275 28.7052 62.7366 28.7052 62.5034V38.3318C28.7052 38.0986 28.8981 37.9077 29.1339 37.9077H39.1405L40.057 35.374L41.2844 31.9709H54.7104L55.9377 35.374L56.8542 37.9077H66.8661C67.102 37.9077 67.2949 38.0986 67.2949 38.3318V62.5034ZM48 41.3003C43.2621 41.3003 39.4245 45.0956 39.4245 49.7815C39.4245 54.4674 43.2621 58.2628 48 58.2628C52.738 58.2628 56.5755 54.4674 56.5755 49.7815C56.5755 45.0956 52.738 41.3003 48 41.3003ZM48 54.8703C45.1594 54.8703 42.8547 52.5909 42.8547 49.7815C42.8547 46.9721 45.1594 44.6928 48 44.6928C50.8407 44.6928 53.1453 46.9721 53.1453 49.7815C53.1453 52.5909 50.8407 54.8703 48 54.8703Z"
          fill={baseColor}
        />
      </svg>
    );
  };

  const handleSubmit = async () => {
    if (
      formData.newPass &&
      formData.cnfPass &&
      formData.newPass !== formData.cnfPass
    ) {
      setErrorToast(true);
      setErrorText("Passwords mismatch!");
    } else if (
      (formData.newPass || formData.oldPass || formData.cnfPass) &&
      !(formData.newPass && formData.oldPass && formData.cnfPass)
    ) {
      setErrorToast(true);
      setErrorText("Passwords are empty!");
    } else {
      const body = { ...formData };
      body.oldPassword = formData.oldPass ? formData.oldPass : null;
      body.newPassword = formData.newPass ? formData.newPass : null;
      delete body.oldPass;
      delete body.cnfPass;
      delete body.newPass;
      const res = await updatePhotogProfile(auth?.profile?._id, body);
      if (res.error) {
        if (res.error.status === 401) {
          setPasswordError(true);
        }
        return;
      }
      setShowModal(false);
    }
  };

  const renderEditForm = () => {
    return (
      <>
        {!loading && (
          <>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Edit Profile</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonList>
              <IonItemDivider>
                <IonIcon icon={personCircleOutline} />
                <h4>Profile Details</h4>
              </IonItemDivider>
              <IonItem>
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput
                  value={formData.name}
                  placeholder="Name"
                  onIonChange={(e) =>
                    setFormData({ ...formData, name: e.detail.value! })
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Address</IonLabel>
                <IonInput
                  value={formData.home_address}
                  placeholder="Address"
                  onIonChange={(e) =>
                    setFormData({
                      ...formData,
                      home_address: e.detail.value!,
                    })
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Phone</IonLabel>
                <IonInput
                  value={formData.phone}
                  placeholder="Phone"
                  onIonChange={(e) =>
                    setFormData({ ...formData, phone: e.detail.value! })
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Email</IonLabel>
                <IonInput
                  value={formData.email}
                  placeholder="Email"
                  onIonChange={(e) =>
                    setFormData({ ...formData, email: e.detail.value! })
                  }
                ></IonInput>
              </IonItem>
              <IonItemDivider style={{ marginTop: 20 }}>
                <IonIcon icon={lockClosedOutline} />
                <h4>Reset Password</h4>
              </IonItemDivider>
              <IonItem>
                <IonLabel position="stacked">Current Password</IonLabel>
                <IonInput
                  type="password"
                  value={formData.oldPass}
                  onIonChange={(e) =>
                    setFormData({ ...formData, oldPass: e.detail.value! })
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">New Password</IonLabel>
                <IonInput
                  type="password"
                  value={formData.newPass}
                  onIonChange={(e) =>
                    setFormData({ ...formData, newPass: e.detail.value! })
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Confirm Password</IonLabel>
                <IonInput
                  type="password"
                  value={formData.cnfPass}
                  onIonChange={(e) =>
                    setFormData({ ...formData, cnfPass: e.detail.value! })
                  }
                ></IonInput>
              </IonItem>
              {passwordError && (
                <IonItem>
                  <IonText color="danger">
                    <h6>
                      Your Current password is not correct to reset your
                      password but rest of the data is updated.
                    </h6>
                  </IonText>
                </IonItem>
              )}
              <div className="profile-actions">
                <div style={{ marginTop: 20 }}>
                  <IonButton
                    color="primary"
                    fill="outline"
                    expand="block"
                    onClick={handleSubmit}
                  >
                    Save
                  </IonButton>
                </div>
                <div style={{ marginTop: 10 }}>
                  <IonButton
                    color="tertiary"
                    fill="outline"
                    expand="block"
                    onClick={() => setShowModal(false)}
                    style={{ marginTop: 40 }}
                  >
                    Cancel
                  </IonButton>
                </div>
                <IonToast
                  isOpen={errorToast}
                  onDidDismiss={() => setErrorToast(false)}
                  message={errorText}
                  duration={2000}
                />
              </div>
            </IonList>
          </>
        )}
      </>
    );
  };

  const handleLogout = () => {
    let socket = socketIOClient.connect(
      process.env.REACT_APP_WS_URL as string,
      {
        transports: ["websocket"],
      }
    );
    logoutUser();
    socket.emit("logout", auth?.profile?._id);
    history.push("/login");
  };

  const handleDeactivate = async () => {
    // Edit profile to set active to false
    const res = await updatePhotogProfile(auth?.profile?._id, {
      user: auth?.user?._id,
      name: auth?.profile?.name,
      home_address: auth?.profile?.home_address,
      phone: auth?.profile?.phone,
      email: auth?.user?.email,
      updatedStatus: false,
    });
    if (res.error) {
      if (res.error.status === 401) {
        console.log("Error in deactivating profile");
      }
      return;
    }

    let socket = socketIOClient.connect(
      process.env.REACT_APP_WS_URL as string,
      {
        transports: ["websocket"],
      }
    );
    logoutUser();
    socket.emit("logout", auth?.profile?._id);
    history.push("/login");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
          <IonTitle>My Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="profile">
          <IonCard>
            <div className="profile-pic">
              <div className="user-pic">
                {auth?.profile?.headshot_url ? (
                  <IonAvatar>
                    <img
                      src={auth?.profile?.headshot_url}
                      alt="Profile Picture"
                    />
                  </IonAvatar>
                ) : (
                  renderDefaultAvatar(auth?.profile?.color)
                )}
              </div>
              <div className="user-name">
                <h3>{auth?.profile?.name}</h3>
              </div>
            </div>
          </IonCard>
          <IonCard>
            <div className="profile-details">
              <div className="section-title">Details</div>
              <IconDetail
                icon={<img src={LocationIcon} alt="Home Address" />}
                detail={auth?.profile?.home_address}
              />
              <IconDetail
                icon={<img src={PhoneIcon} alt="Phone" />}
                detail={auth?.profile?.phone}
              />
              <IconDetail
                icon={<img src={MailIcon} alt="Email" />}
                detail={auth?.user?.email}
              />

              <IonItem>
                <IonButton
                  fill="outline"
                  slot="end"
                  onClick={() => setShowModal(true)}
                >
                  Edit Profile
                </IonButton>
              </IonItem>
            </div>
          </IonCard>
          <IonCard>
            <PayBreakdown />
          </IonCard>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <IonButton
            color="tertiary"
            expand="block"
            fill="outline"
            onClick={handleLogout}
          >
            Logout
          </IonButton>
        </div>
        <div style={{ margin: "10px 10px 30px 10px" }}>
          <IonButton
            color="danger"
            expand="block"
            fill="outline"
            onClick={() =>
              presentAlert({
                header: "Are you sure?",
                message:
                  "This will deactivate your account and you will not be able to log in again.",
                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                  },
                  {
                    text: "OK",
                    role: "confirm",
                    handler: () => {
                      handleDeactivate();
                    },
                  },
                ],
              })
            }
          >
            Deactivate Account
          </IonButton>
        </div>

        <IonModal isOpen={showModal}>
          <IonContent>
            <div className="modal-content">
              <div className="edit-profile">{renderEditForm()}</div>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    loading: state.loading,
  };
};

export default connect(mapStateToProps, { logoutUser, updatePhotogProfile })(
  Profile
);
