import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import CustomIcon from "../assets/images/arrow.png";

interface mapProps {
  width: string;
  height: string;
  centerLat: number;
  centerLng: number;
  defaultZoom: number;
  markerData: any;
}

const Map: React.FC<mapProps> = ({
  width,
  height,
  centerLat,
  centerLng,
  defaultZoom,
  markerData,
}) => {
  const containerStyle = {
    width: width,
    height: height,
  };

  const center = {
    lat: centerLat,
    lng: centerLng,
  };

  return (
    <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API}`}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={defaultZoom}
      >
        <Marker position={{ lat: centerLat, lng: centerLng }} />
        if (markerData)
        {markerData.map((marker: any) => {
          return (
            <Marker
              key={marker?._id}
              position={{
                lat: marker?.geometry?.coordinates[0],
                lng: marker?.geometry?.coordinates[1],
              }}
              label={marker?.name}
              icon={{
                url: CustomIcon,
              }}
            />
          );
        })}
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(Map);
