import React, { useEffect, useState } from "react";
import "./EmptyState.css";
import EmptyImageLight from "../assets/images/empty-light.svg";
import EmptyImageDark from "../assets/images/empty-dark.svg";

const EmptyState: React.FC = () => {
  useEffect(() => {
    const systemDark = window.matchMedia("(prefers-color-scheme: dark)");
    setDarkMode(systemDark.matches);
  }, []);

  const [darkMode, setDarkMode] = useState(false);

  return (
    <div className="empty-state">
      <div className="empty-state_image">
        {darkMode ? (
          <img src={EmptyImageDark} alt="Home Snappers" />
        ) : (
          <img src={EmptyImageLight} alt="Home Snappers" />
        )}
        <div className="empty-state_text">No shoots to show</div>
      </div>
    </div>
  );
};

export default EmptyState;
