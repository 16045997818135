import React, { useRef, useState } from "react";
import {
  IonCheckbox,
  IonIcon,
  IonItem,
  IonButton,
  IonHeader,
  IonModal,
  IonToolbar,
  IonTextarea,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { cashOutline, close } from "ionicons/icons";
import "./CompleteShoot.css";

interface componentProps {
  reason: string;
  setReason: (reason: string) => void;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  isCompletedShoot: boolean;
  setIsCompletedShoot: any;
  completeHandler: any;
  incompleteHandler: any;
  requestReschedule: boolean;
  setRequestReschedule: any;
  markAsUpsell: boolean;
  setMarkAsUpsell: any;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const CompleteShoot: React.FC<componentProps> = ({
  reason,
  setReason,
  submitting,
  setSubmitting,
  setIsCompletedShoot,
  isCompletedShoot,
  completeHandler,
  incompleteHandler,
  requestReschedule,
  setRequestReschedule,
  markAsUpsell,
  setMarkAsUpsell,
  showModal,
  setShowModal,
}) => {
  const [showReasonInput, setShowReasonInput] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    modal.current?.dismiss();
  }

  const handleCompleteShoot = async () => {
    setSubmitting(true);
    setIsCompletedShoot(true);
    await completeHandler();
  };

  const handleIncompleteShoot = async () => {
    setSubmitting(true);
    setIsCompletedShoot(true);
    await incompleteHandler();
  };

  const handleCheckboxChange = (e: any) => {
    const { checked } = e.target;
    setRequestReschedule(checked);
  };

  const handleUpsellCheckboxChange = (e: any) => {
    const { checked } = e.target;
    setMarkAsUpsell(checked);
  };

  const renderReason = () => {
    return (
      <>
        <h4>Please give a reason or describe the change below:</h4>
        <IonTextarea
          clearOnEdit={true}
          autoGrow={true}
          value={reason}
          onIonChange={(e) => setReason(e.detail.value!)}
          className="contact-template-textarea"
        ></IonTextarea>
        <IonItem>
          <IonCheckbox
            slot="start"
            onIonChange={handleCheckboxChange}
            value={requestReschedule}
          ></IonCheckbox>
          <IonLabel>Request a reschedule</IonLabel>
        </IonItem>
        <IonItem>
          <IonCheckbox
            slot="start"
            onIonChange={handleUpsellCheckboxChange}
            value={markAsUpsell}
          ></IonCheckbox>
          <IonLabel>
            <span role="img" aria-label="money with wings">
              💸
            </span>{" "}
            Mark as upsell
          </IonLabel>
        </IonItem>
      </>
    );
  };

  return (
    <>
      <IonButton
        size="small"
        color="success"
        id="open-custom-dialog"
        disabled={isCompletedShoot || submitting}
        onClick={() => setShowModal(true)}
      >
        Complete
      </IonButton>
      <IonModal id="complete-modal" isOpen={showModal} backdropDismiss={false}>
        <div>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton fill="clear" onClick={() => setShowModal(false)}>
                  <IonIcon icon={close}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        </div>
        <div className="wrapper">
          {showReasonInput ? (
            renderReason()
          ) : (
            <>
              <h3>Did you complete all services as requested?</h3>
              <p>
                <strong>NOTE:</strong> If the client asked or agreed to change
                any services please select 'No' below.
              </p>
            </>
          )}
          <div className="action-buttons">
            {!showReasonInput ? (
              <>
                <IonButton
                  color="danger"
                  onClick={() => setShowReasonInput(true)}
                  style={{ marginRight: 10 }}
                >
                  No
                </IonButton>
                <IonButton
                  color="success"
                  onClick={() => handleCompleteShoot()}
                  disabled={isCompletedShoot || submitting}
                >
                  Yes
                </IonButton>
              </>
            ) : (
              <>
                <IonButton
                  color="light"
                  onClick={() => setShowModal(false)}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </IonButton>
                <IonButton
                  color="success"
                  onClick={() => handleIncompleteShoot()}
                  disabled={
                    isCompletedShoot || submitting || !reason || reason === ""
                  }
                >
                  Submit
                </IonButton>
              </>
            )}
          </div>
        </div>
      </IonModal>
    </>
  );
};

export default CompleteShoot;
