import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTextarea,
  useIonActionSheet,
  useIonToast,
  IonSpinner,
} from "@ionic/react";
import { callOutline, chatbubblesOutline, mailOutline } from "ionicons/icons";
import "./ContactButtons.css";
import { formatTelForSms } from "../helpers/utils";

interface contactButtonsProps {
  auth: any;
  item: any;
  clientTel: string;
  clientEmail: string;
  clientName: string;
  photog: any;
  hsn: number;
  address: any;
  createActivity: any;
  position: any;
}

const ContactButtons: React.FC<contactButtonsProps> = ({
  auth,
  item,
  clientTel,
  clientEmail,
  photog,
  clientName,
  hsn,
  address,
  createActivity,
  position,
}) => {
  const [present] = useIonActionSheet();
  const [presentToast] = useIonToast();
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const templateText = {
    early:
      "🏎️💨 Your Home Snappers photographer is on the way, ahead of schedule. See you soon!",
    early5:
      "🏎️💨 Your Home Snappers photographer is early, and almost at the property.",
    late5:
      "🚙💨 Your Home Snappers photographer is running about 5 minutes behind. Sorry for the delay.",
    late10:
      "🚙💨 Your Home Snappers photographer is running about 10 minutes behind. Sorry for the delay.",
    late15:
      "🚙💨 Your Home Snappers photographer is running about 15 minutes behind. Sorry for the delay.",
    late20:
      "🚙💨 Your Home Snappers photographer is running about 20 minutes behind. Sorry for the delay.",
    noAccess: `Hi. Home Snappers, here 👋. Your Home Snappers photographer is not able to access the property.`,
    noAccessCbs:
      "Hi. Home Snappers, here 👋. The Supra CBS code seems to not be working.",
    noAccessCombination:
      "Hi. Home Snappers, here 👋. The combination code seems to not be working.",
    badWeatherWinds:
      "High winds are preventing your Home Snappers photographer from completing some or all services outside. Check in with our support team for more info. Thanks.",
    badWeatherRain:
      "🌧 Rain is preventing your Home Snappers photographer from completing some or all services outside. Check in with our support team for more info. Thanks.",
    badWeatherSnow:
      "🌨 Snow is preventing your Home Snappers photographer from completing some or all services outside. Check in with our support team for more info. Thanks.",
    badWeatherFog:
      "😶‍🌫️ Fog is preventing your Home Snappers photographer from completing some or all services outside. Check in with our support team for more info. Thanks.",
    badWeather:
      "Weather is preventing your Home Snappers photographer from completing some or all services outside. Check in with our support team for more info. Thanks.",
    noFly:
      "Home Snappers, here 👋. The property is in a drone no fly zone. Drone services will be removed from your invoice. Check in with our support team with questions.",
    custom: "",
  };

  const renderTemplate = (): JSX.Element => {
    return (
      <>
        <IonPage>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonList>
                    <IonRadioGroup
                      value={selectedTemplate}
                      onIonChange={(e) => handleSelectTemplate(e.detail.value)}
                    >
                      {templateType === "Ahead of Schedule" && (
                        <>
                          <IonListHeader>
                            <IonLabel>Ahead of Schedule</IonLabel>
                          </IonListHeader>
                          <IonItem>
                            <IonLabel>On my way!</IonLabel>
                            <IonRadio slot="start" value={templateText.early} />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Almost there</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.early5}
                            />
                          </IonItem>
                        </>
                      )}
                      {templateType === "Running Late" && (
                        <>
                          <IonListHeader>
                            <IonLabel>Running Late</IonLabel>
                          </IonListHeader>
                          <IonItem>
                            <IonLabel>5 minutes late</IonLabel>
                            <IonRadio slot="start" value={templateText.late5} />
                          </IonItem>
                          <IonItem>
                            <IonLabel>10 minutes late</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.late10}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel>15 minutes late</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.late15}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel>20 minutes late</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.late20}
                            />
                          </IonItem>
                        </>
                      )}
                      {templateType === "No Access" && (
                        <>
                          <IonListHeader>
                            <IonLabel>No Access</IonLabel>
                          </IonListHeader>
                          <IonItem>
                            <IonLabel>CBS Problems</IonLabel>
                            <IonRadio
                              slot="start"
                              value={`${templateText.noAccessCbs} Please call or text ${photog?.virtual_phone} immediately. We can wait 15 mins before rescheduling.`}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Combination Problems</IonLabel>
                            <IonRadio
                              slot="start"
                              value={`${templateText.noAccessCombination} Please call or text ${photog?.virtual_phone} immediately. We can wait 15 mins before rescheduling.`}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Generic</IonLabel>
                            <IonRadio
                              slot="start"
                              value={`${templateText.noAccess} Please call or text ${photog?.virtual_phone} immediately. We can wait 15 mins before rescheduling.`}
                            />
                          </IonItem>
                        </>
                      )}
                      {templateType === "Weather" && (
                        <>
                          <IonListHeader>
                            <IonLabel>Weather/Technical</IonLabel>
                          </IonListHeader>
                          <IonItem>
                            <IonLabel>No Fly Zone</IonLabel>
                            <IonRadio slot="start" value={templateText.noFly} />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Weather - High winds 🌬</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.badWeatherWinds}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Weather - Rain ⛈</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.badWeatherRain}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Weather - Snow 🌨</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.badWeatherSnow}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Weather - Generic 🌦</IonLabel>
                            <IonRadio
                              slot="start"
                              value={templateText.badWeather}
                            />
                          </IonItem>
                        </>
                      )}
                    </IonRadioGroup>
                  </IonList>
                </IonCol>
              </IonRow>
              {templateType === "Custom" && (
                <IonRow>
                  <IonCol>
                    {" "}
                    <>
                      <IonListHeader>
                        <IonLabel>Custom Message</IonLabel>
                      </IonListHeader>
                      <p>Start typing below:</p>
                    </>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol>
                  <IonTextarea
                    clearOnEdit={true}
                    autoGrow={true}
                    value={selectedTemplate}
                    onIonChange={(e) => setSelectedTemplate(e.detail.value!)}
                    className="contact-template-textarea"
                  ></IonTextarea>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    color="tertiary"
                    expand="block"
                    onClick={() => handleSubmit()}
                    disabled={
                      selectedTemplate === "" ? true : false || submitting
                    }
                  >
                    {submitting ? <IonSpinner name="crescent" /> : "Send"}
                  </IonButton>
                  <IonButton
                    color="tertiary"
                    expand="block"
                    fill="outline"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  };

  const handleSelectTemplate = (value: any) => {
    setSelectedTemplate(value);
  };

  const handleTemplateType = (templateName: string): void => {
    setShowModal(true);
    setTemplateType(templateName);
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
    setSelectedTemplate("");
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const payload = {
      tel: formatTelForSms(clientTel),
      message: selectedTemplate,
      clientName,
      clientEmail,
      photogName: photog?.name,
      photogPhone: photog?.phone,
      hsn,
      street: address?.street.replace("null", ""),
      city: address?.city,
      state: address?.state,
      zip: address?.zip,
    };

    try {
      await fetch("https://hooks.zapier.com/hooks/catch/412492/beqwasy/", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      presentToast({
        message: "Message sent successfully",
        duration: 2000,
        color: "success",
      });
      await createActivity(
        {
          ...item,
          currentLocation: {
            latitude: position?.latitude,
            longitude: position?.longitude,
          },
        },
        selectedTemplate,
        auth?.user?._id
      );
      setShowModal(false);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      presentToast({
        message: "Message failed to send",
        duration: 2000,
        color: "danger",
      });
    }

    // console.log(res);
  };

  return (
    <div className="contact-actions" style={{ display: "flex" }}>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol>
            {" "}
            <a href={`tel:${formatTelForSms(clientTel)}`}>
              <IonButton size="small" color="light" expand="block">
                <IonIcon slot="icon-only" icon={callOutline} />
              </IonButton>
            </a>
          </IonCol>
          <IonCol>
            <IonButton
              size="small"
              expand="block"
              color="light"
              onClick={() =>
                present({
                  buttons: [
                    {
                      text: "Ahead of Schedule",
                      handler: () => handleTemplateType("Ahead of Schedule"),
                    },
                    {
                      text: "Running Late",
                      handler: () => handleTemplateType("Running Late"),
                    },
                    {
                      text: "No Access",
                      handler: () => handleTemplateType("No Access"),
                    },
                    {
                      text: "Weather",
                      handler: () => handleTemplateType("Weather"),
                    },
                    {
                      text: "Custom",
                      handler: () => handleTemplateType("Custom"),
                    },
                    { text: "Cancel", role: "cancel" },
                  ],
                  header: "Choose a Template",
                })
              }
            >
              <IonIcon slot="icon-only" icon={chatbubblesOutline} />
            </IonButton>
          </IonCol>
          <IonCol>
            {" "}
            <a href={`mailto:${clientEmail}`}>
              <IonButton size="small" color="light" expand="block">
                <IonIcon slot="icon-only" icon={mailOutline} />
              </IonButton>
            </a>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonModal
        isOpen={showModal}
        //cssClass="my-custom-class"
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
      >
        {renderTemplate()}
      </IonModal>
    </div>
  );
};

export default ContactButtons;
