import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonIcon,
  IonItem,
  IonBackButton,
  IonButtons,
  IonText,
} from "@ionic/react";
import "./Help.css";
import { callOutline, mailOutline } from "ionicons/icons";

interface helpProps {
  loading: boolean;
}

const Help: React.FC<helpProps> = ({ loading }) => {
  const renderAccordionItem = (
    description: string,
    price1: number,
    price2: number,
    price3: number
  ) => {
    return (
      <div className="help-item-wrapper">
        <div>
          <h4>{description}</h4>
        </div>
        {price1 > 0 && (
          <div className="pricing residential">{`$${price1}`}</div>
        )}
        {price2 > 0 && (
          <div className="pricing multifamily">{`$${price2}`}</div>
        )}
        {price3 > 0 && <div className="pricing commercial">{`$${price3}`}</div>}
      </div>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
          <IonTitle>Help</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="help">
          <div className="ion-padding">
            Key: <span className="residential key-item">Residential</span>{" "}
            <span className="multifamily key-item">Multi-family</span>
            <span className="commercial key-item">Commercial</span>{" "}
          </div>
          <IonAccordionGroup>
            <IonAccordion value="photo-packages">
              <IonItem slot="header" color="light">
                <IonLabel>Interior/Exterior Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("15 Photos", 140, 264, 314)}
                {renderAccordionItem("25 Photos", 174, 294, 344)}
                {renderAccordionItem("36 Photos", 209, 319, 369)}
                {renderAccordionItem("42 Photos", 229, 339, 389)}
                {renderAccordionItem("50 Photos", 244, 354, 404)}
                {renderAccordionItem("Amenities", 69, 199, 249)}
                {renderAccordionItem(
                  "*Deluxe Photo Package (only Residential)",
                  89,
                  0,
                  0
                )}
                <IonText color="medium">
                  <sub>*$500,000+, or 3,500+ sqft. Min. 25 photos </sub>
                </IonText>
              </div>
            </IonAccordion>
            <IonAccordion value="video-packages">
              <IonItem slot="header" color="light">
                <IonLabel>Video Package Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("Walkthrough", 314, 464, 464)}
                {renderAccordionItem("Cinematic", 494, 614, 614)}
                {renderAccordionItem(
                  "Walkthrough + Aerial (photo)",
                  364,
                  664,
                  714
                )}
                {renderAccordionItem(
                  "Walkthrough + Aerial (no photo)",
                  414,
                  714,
                  764
                )}
                {renderAccordionItem(
                  "Cinematic + Aerial (photo)",
                  564,
                  814,
                  864
                )}
                {renderAccordionItem(
                  "Cinematic + Aerial (no photo)",
                  614,
                  864,
                  914
                )}
                {renderAccordionItem("Social Media Video", 144, 274, 324)}
              </div>
            </IonAccordion>
            <IonAccordion value="aerial-packages">
              <IonItem slot="header" color="light">
                <IonLabel>Aerial Package Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("1 Aerial (photo only)", 99, 149, 249)}
                {renderAccordionItem("5 Aerials (photo only)", 129, 209, 309)}
                {renderAccordionItem("10 Aerials (photo only)", 144, 244, 344)}
                {renderAccordionItem("20 Aerials (photo only)", 164, 304, 404)}

                {renderAccordionItem(
                  "1 Aerial (photo w/ video)",
                  244,
                  364,
                  514
                )}
                {renderAccordionItem(
                  "5 Aerials (photo w/ video)",
                  264,
                  414,
                  574
                )}
                {renderAccordionItem(
                  "10 Aerials (photo w/ video)",
                  274,
                  444,
                  604
                )}
                {renderAccordionItem(
                  "20 Aerials (photo w/ video)",
                  294,
                  504,
                  664
                )}
              </div>
            </IonAccordion>
            <IonAccordion value="addons">
              <IonItem slot="header" color="light">
                <IonLabel>Add-on Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("Custom Flyer", 45, 135, 235)}
                {renderAccordionItem("Twilight Timeslot", 89, 169, 269)}
                {renderAccordionItem("Youtube Slideshow", 45, 135, 235)}
                {renderAccordionItem(
                  "Dollhouse VR (based on sqft) starts at $109",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem("Dollhouse Floorplan", 34, 134, 189)}
              </div>
            </IonAccordion>
            <IonAccordion value="turnaround">
              <IonItem slot="header" color="light">
                <IonLabel>Turnaround Times</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem(
                  "Photo Next Day by 12:00p (standard)",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem("Photo Same Day by 9:00p", 49, 79, 239)}
                {renderAccordionItem("Photo 4-Hours Turnaround", 89, 129, 269)}
                {renderAccordionItem(
                  "Video 2 Business Days by 12:00p (standard)",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem(
                  "Video Next Business Day by 9:00p",
                  49,
                  99,
                  239
                )}
                {renderAccordionItem(
                  "Video Next Business Day by 12:00p",
                  89,
                  149,
                  269
                )}
                {renderAccordionItem(
                  "Dollhouse VR One Business Day (standard)",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem(
                  "Dollhouse Floorplan 2 days after VR is uploaded",
                  0,
                  0,
                  0
                )}
              </div>
            </IonAccordion>
            <IonAccordion value="fees">
              <IonItem slot="header" color="light">
                <IonLabel>Fees</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem(
                  "Properties over 4K sqft (photos)",
                  49,
                  49,
                  99
                )}
                {renderAccordionItem(
                  "Properties over 4K sqft (video)",
                  99,
                  99,
                  149
                )}
                {renderAccordionItem("Cancel/Reschedule", 99, 99, 99)}
                {renderAccordionItem(
                  "Cancel/Reschedule within 12h",
                  50,
                  50,
                  50
                )}
                {renderAccordionItem(
                  "Cancel/Reschedule within 24h",
                  25,
                  25,
                  25
                )}
              </div>
            </IonAccordion>
            <IonAccordion value="comms">
              <IonItem slot="header" color="light">
                <IonLabel>How to Contact Comms</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <div style={{ marginBottom: 25 }}>
                  <h4>
                    <IonIcon slot="icon-only" icon={callOutline} />{" "}
                    <a href="tel:972-533-6621">(972) 533-6621</a>
                  </h4>
                </div>
                <div style={{ marginBottom: 25 }}>
                  <h4>
                    <IonIcon slot="icon-only" icon={mailOutline} />{" "}
                    <a href="mailto:book@homesnappers.com">
                      book@homesnappers.com
                    </a>
                  </h4>
                </div>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Help;
