import React, { useEffect, useState } from "react";
import {
  IonInput,
  IonPage,
  IonContent,
  IonButton,
  IonItem,
  IonLoading,
} from "@ionic/react";
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions";
import logo from "../../assets/images/hs_logo_on_dark.png";
import "./register.css";

interface registerProps {
  registerUser: any;
  authenticated: boolean;
  auth: any;
  error: any;
  history: any;
}
const Register: React.FC<registerProps> = ({
  history,
  authenticated,
  registerUser,
}) => {
  useEffect(() => {
    if (authenticated) {
      history.push("/dashboard");
    }
  }, [authenticated]);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [photogId, setPhotogId] = useState("");
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const signup = () => {
    setShowLoading(true);
    registerUser({
      name: fullName,
      email: email,
      photog_id: photogId,
      createPassword: password,
    });
    setShowLoading(false);
  };
  return (
    <IonPage>
      <IonContent>
        <div className="register-wrapper">
          <div className="logo">
            <img src={logo} alt="HomeSnappers" />
          </div>
          <div className="register-card">
            <div className="register-form">
              <IonItem>
                <IonInput
                  type="text"
                  placeholder="Full Name"
                  onIonChange={(e: any) => setFullName(e.detail.value)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  type="text"
                  placeholder="Email"
                  onIonChange={(e: any) => setEmail(e.detail.value)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  type="text"
                  placeholder="Photographer ID"
                  onIonChange={(e: any) => setPhotogId(e.detail.value)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  type="password"
                  placeholder="Password"
                  onIonChange={(e: any) => setPassword(e.detail.value)}
                ></IonInput>
              </IonItem>
            </div>
            <IonButton
              expand="block"
              onClick={signup}
              type="submit"
              className="registerBtn"
            >
              Register
            </IonButton>

            <p style={{ textAlign: "center" }}>
              Already registered?{" "}
              <a href="/login">
                <strong>Sign in.</strong>
              </a>
            </p>
          </div>
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  authenticated: state.authenticated,
});

export default connect(mapStateToProps, {
  registerUser,
})(Register);
