import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  IonContent,
  IonToast,
  IonPage,
  IonItem,
  IonInput,
  IonButton,
} from "@ionic/react";

import { loginUser, loadCurrentUser } from "../redux/actions";
import "./Login.css";
import logo from "../assets/images/hs_logo_on_dark.png";

interface loginProps {
  loginUser: any;
  loadCurrentUser: any;
  loading: boolean;
  authenticated: boolean;
  auth: any;
  error: any;
  history: any;
}

const Login: React.FC<loginProps> = ({
  history,
  authenticated,
  loginUser,
  loadCurrentUser,
  auth,
}) => {
  useEffect(() => {
    if (authenticated) {
      history.push("/dashboard");
    }
  }, [authenticated, auth]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = async () => {
    const res = await loginUser(email, password);
    if (res.status !== 200) {
      setErrorMessage(res.data);
      setShowToast(true);
      return;
    }
    loadCurrentUser();
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="login-wrapper">
          <div className="logo">
            <img src={logo} alt="HomeSnappers" />
          </div>
          <div className="login-card">
            <div className="input-group">
              <IonItem>
                <IonInput
                  type="text"
                  placeholder="Email"
                  onIonChange={(e: any) => setEmail(e.detail.value)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  type="password"
                  placeholder="Password"
                  onIonChange={(e: any) => setPassword(e.detail.value)}
                ></IonInput>
              </IonItem>
              <div className="actions">
                <IonButton
                  fill="solid"
                  color="primary"
                  expand="block"
                  onClick={handleSubmit}
                >
                  Sign In
                </IonButton>

                <a href="/forget-password">
                  <p>Forgot your password?</p>
                </a>

                {/* <div className="social-buttons">
                  <IonButton
                    fill="solid"
                    color="light"
                    expand="block"
                    onClick={handleGoogleLogin}
                  >
                    <IonIcon
                      style={{ color: "#db3236" }}
                      slot="icon-only"
                      icon={logoGoogle}
                    />
                    Sign In with Google
                  </IonButton>
                  <IonButton
                    fill="solid"
                    color="light"
                    expand="block"
                    onClick={handleFBLogin}
                  >
                    <IonIcon
                      style={{ color: "#3b5998" }}
                      slot="icon-only"
                      icon={logoFacebook}
                    />
                    Sign In with Facebook
                  </IonButton>
                </div> */}
              </div>
              {/* <p style={{ textAlign: "center" }}>
                Are you a Home Snappers photographer?{" "}
              </p>
              <IonButton
                routerLink={"/register"}
                expand="full"
                color="light"
                size="small"
              >
                Sign up
              </IonButton> */}
            </div>
          </div>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={errorMessage}
        duration={3000}
      />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  authenticated: state.authenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  loginUser,
  loadCurrentUser,
})(Login);
