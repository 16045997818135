import React, { useRef, useState } from "react";
import { IonIcon } from "@ionic/react";
import { chevronUpOutline } from "ionicons/icons";
import "./Accordion.css";

interface accordionProps {
  content: any;
  title: any;
  icon: any;
  expand: boolean;
}

const Accordion: React.FC<accordionProps> = ({
  content,
  title,
  icon,
  expand,
}) => {
  const [active, setActive] = useState("active");
  const contentRef = useRef<any>(null);
  const [height, setHeight] = useState(
    expand ? `${contentRef?.current?.scrollHeight}px` : "0px"
  );
  const [rotate, setRotate] = useState("accordion-icon rotate");

  const toggleAccordion = () => {
    setActive(active === "" ? "active" : "");
    if (!expand) {
      setHeight(
        active === "active" ? `${contentRef?.current?.scrollHeight}px` : "0px"
      );
    } else {
      setHeight(
        active === "active" ? "0px" : `${contentRef?.current?.scrollHeight}px`
      );
    }

    setRotate(active === "active" ? "accordion-icon" : "accordion-icon rotate");
  };

  // const renderIcon = () => {
  //   return active === "active" (
  //     <IonIcon slot="icon-only" icon={chevronDownOutline} />
  //   ) : (
  //     <IonIcon slot="icon-only" icon={chevronUpOutline} />
  //   );
  // };

  return (
    <div className="accordion-section">
      <button className={`accordion ${active}`} onClick={toggleAccordion}>
        <span className="prefix-icon">{icon}</span> {title}
        <span className={`${rotate}`}>
          <IonIcon slot="icon-only" icon={chevronUpOutline} />
        </span>
      </button>
      <div
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
        className="accordion-content"
      >
        <div className="accordion-content-divider"></div>
        {content}
      </div>
    </div>
  );
};

export default Accordion;
