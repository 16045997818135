import React from "react";
import "./IconDetail.css";

interface iconDetailProps {
  icon: any;
  detail: string;
}

const IconDetail: React.FC<iconDetailProps> = ({ icon, detail }) => {
  return (
    <div className="icon-detail">
      <div className="icon">{icon}</div>
      <div className="detail">{detail}</div>
    </div>
  );
};

export default IconDetail;
