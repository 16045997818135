import moment from "moment";

const addZero = (number: any) => {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
};

const convert12HrFmt = (hr: any) => {
  if (hr === 0) {
    return 12;
  } else if (hr > 12) {
    return hr - 12;
  } else {
    return hr;
  }
};

export const roundedCurrency = (amount: number) => {
  return amount ? `$${amount.toFixed(2)}` : "$0.00";
};
export const roundOffNumber = (number: number, decimal = 2) => {
  let result =
    Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
  return result;
};

export const convertISOStrToDate = (ISODateStr: any) => {
  const localDateStr =
    ISODateStr.split("T")[0] + " " + ISODateStr.split("T")[1].split(".")[0];
  console.log(localDateStr);
  return new Date(localDateStr);
};

export const convertDateToTime = (date: any) => {
  const hour = convert12HrFmt(date.getHours());
  const miniute = addZero(date.getMinutes());
  const session = date.getHours() < 12 ? "A" : "P";
  return hour + ":" + miniute + session;
};

export const convertISODateString = (timestamp: any) => {
  const date = addZero(timestamp.getDate());
  const month = addZero(timestamp.getMonth() + 1);
  const year = timestamp.getFullYear();
  const hour = addZero(timestamp.getHours());
  const miniute = addZero(timestamp.getMinutes());
  const second = addZero(timestamp.getSeconds());
  return (
    year +
    "-" +
    month +
    "-" +
    date +
    "T" +
    hour +
    ":" +
    miniute +
    ":" +
    second +
    ".000Z"
  );
};

export const eventDateFormat = (from: string, to: string) => {
  const date =
    moment.utc(from).format("MMM D") +
    ", " +
    moment.utc(from).format("h:mm A") +
    "-" +
    moment.utc(to).format("h:mm A");
  return date;
};

export const eventTimeFormat = (from: string, to: string) => {
  const date =
    moment.utc(from).format("h:mm A") + "-" + moment.utc(to).format("h:mm A");
  return date;
};

export const chipColor = (status: string) => {
  if (status === "Tentative") {
    return "dark";
  } else if (status === "Scheduled") {
    return "success";
  } else if (status === "Processing") {
    return "secondary";
  } else if (status === "Postpone") {
    return "tertiary";
  } else if (status === "Reschedule") {
    return "light";
  } else {
    return "danger";
  }
};
export const photoCodes = [
  "e5",
  "Me5",
  "Ce5",
  "e8",
  "Ae8",
  "Me8",
  "Ce8",
  "e10",
  "Me10",
  "Ce10",
  "e15",
  "Me15",
  "Ce15",
  "Me20",
  "Ce20",
  "ie5",
  "Mie5",
  "Cie5",
  "ie10",
  "Mie10",
  "Cie10",
  "ie12",
  "Mie12",
  "Cie12",
  "ie15",
  "Mie15",
  "Cie15",
  "ie20",
  "Mie20",
  "Cie20",
  "ie25",
  "Mie25",
  "Cie25",
  "ie36",
  "Mie36",
  "Cie36",
  "ie42",
  "Mie42",
  "Cie42",
  "ie50",
  "Mie50",
  "Cie50",
  "ie75",
  "Mie75",
  "Cie75",
  "ie100",
  "Mie100",
  "Cie100",
  "Aie36",
  "Aie42",
  "Aie50",
  "Aie75",
  "Aie100",
  "av1",
  "5apv",
  "Mav1",
  "Cav1",
  "av3",
  "Mav3",
  "Cav3",
  "av5",
  "Mav5",
  "Cav5",
  "av10",
  "Mav10",
  "Cav10",
  "av20",
  "Mav20",
  "Cav20",
  "av30",
  "Mav30",
  "Cav30",
  "vAp",
  "MvAp",
  "CvAp",
  "vA",
  "MvA",
  "CvA",
  "AvA",
  "vWAp",
  "MvWAp",
  "CvWAp",
  "vWA",
  "MvWA",
  "CvWA",
  "vCAp",
  "vCAnp",
  "vCA",
  "MvCAp",
  "CvCAp",
  "CvCA",
  "vW",
  "MvW",
  "CvW",
  "AvW",
  "vC",
  "MvC",
  "CvC",
  "ae",
  "Mae",
  "Cae",
  "VR",
  "MVR",
  "CVR",
  "AVR",
  "T",
  "TC",
  "MT",
  "CT",
  "RF",
  "RSC",
  "RFC",
  "a25",
  "a50",
  "ad1",
  "ad2",
  "ad3",
  "ad4",
  "ad5",
  "ad6",
  "ad7",
  "ad8",
  "ad9",
  "ad10",
  "ad11",
  "ad12",
  "ad13",
  "ad14",
  "ad15",
  "ad25",
  "ad36",
  "ad42",
  "ad50",
  "Aav1",
  "Aav3",
  "Aav5",
  "Aav10",
  "Aav15",
  "Aav20",
  "Aav25",
  "Aav30",
  "SP",
  "MSP",
  "CSP",
  "SMV",
  "RSMV",
  "MSMV",
  "CSMV",
  "ASMV",
  "TRN1",
  "TRN2",
  "TRN3",
  "TRN4",
  "MTRN",
  "CTRN",
  "ATRN",
  "SAT",
  "MSAT",
  "CSAT",
  "ASAT",
  "DLX",
  "ADLX",
  "KA",
  "a10vA",
  "a15vA",
  "a20vA",
];

export const formatTelForSms = (telNum: string) => {
  let formatted = telNum?.replace(/\D/g, "");
  if (formatted?.length === 10) {
    return `+1${formatted}`;
  } else if (formatted?.length === 11) {
    return `+${formatted}`;
  } else {
    return formatted;
  }
};
