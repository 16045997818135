import React, { useEffect, useState } from "react";
import {
  IonInput,
  IonPage,
  IonContent,
  IonButton,
  IonToast,
  IonText,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { connect } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../redux/actions";
import logo from "../../assets/images/hs_logo_on_light.png";
import "./resetPassword.css";
interface registerProps {
  resetPassword: any;
  history: any;
}
const ResetPassword: React.FC<registerProps> = ({ history, resetPassword }) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [values, setValues] = useState({
    createPassword: "",
    cnfPassword: "",
  });
  const [errors, setErrors] = useState({
    createPassword: "",
    cnfPassword: "",
  });

  const resetPwd = async (e: any) => {
    e.preventDefault();
    const err = runValidations(values);
    if (!(err.cnfPassword || err.createPassword)) {
      const { token } = queryString.parse(window.location.search);
      const response = await resetPassword({
        token,
        password: values.createPassword,
      });
      if (response.error) {
        setToastMessage("Oops, Something went wrong.");
        setShowToast(true);
        return;
      }
      setToastMessage("Your password reset successfully.");
      setShowToast(true);
      history.push("/login");
    }
    return;
  };

  const runValidations = (updatedValues: any) => {
    const err = { ...errors };
    for (const key of Object.keys(updatedValues)) {
      if (!updatedValues[key].trim()) {
        if (key === "createPassword") {
          err.createPassword = "Please enter your password";
        } else {
          err.cnfPassword = "Please enter your confirm password";
        }
      }
      if (updatedValues[key].trim()) {
        if (key === "createPassword") {
          if (updatedValues[key].trim().length < 8) {
            err.createPassword = "Password should be minimum of 8 characters";
          }
        } else {
          if (updatedValues[key] !== updatedValues.createPassword) {
            err.cnfPassword = "Confirm password doesn't match";
          }
        }
      }
    }
    setErrors({ ...err });
    return err;
  };

  const onChange = (val: any, fieldName: any) => {
    if (fieldName === "createPassword") {
      if (!val.trim()) {
        setErrors({ ...errors, createPassword: "Please enter your password" });
      } else if (val.trim().length < 8) {
        setErrors({
          ...errors,
          createPassword: "Password should be minimum of 8 characters",
        });
      } else {
        setErrors({ ...errors, [fieldName]: "" });
      }
    } else {
      if (!val.trim()) {
        setErrors({
          ...errors,
          cnfPassword: "Please enter your confirm password",
        });
      } else if (val !== values.createPassword) {
        setErrors({ ...errors, cnfPassword: "Confirm password doesn't match" });
      } else {
        setErrors({ ...errors, [fieldName]: "" });
      }
    }
    setValues({ ...values, [fieldName]: val });
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div className="reset-wrapper">
          <div className="logo">
            <img src={logo} alt="HomeSnappers" />
          </div>
          <div className="reset-card">
            <form onSubmit={resetPwd}>
              <div className="reset-form">
                <IonItem>
                  <IonLabel position="floating">Create Password</IonLabel>
                  <IonInput
                    type="password"
                    value={values.createPassword}
                    onIonChange={(e: any) =>
                      onChange(e.target.value, "createPassword")
                    }
                  />
                </IonItem>
                {errors && errors["createPassword"] && (
                  <IonText color="danger" className="ion-padding-start">
                    <span style={{ color: "red", fontSize: "75%" }}>
                      {errors["createPassword"]}
                    </span>
                  </IonText>
                )}
                <IonItem>
                  <IonLabel position="floating">Confirm Password</IonLabel>
                  <IonInput
                    type="password"
                    value={values.cnfPassword}
                    onIonChange={(e: any) =>
                      onChange(e.target.value, "cnfPassword")
                    }
                  />
                </IonItem>
                {errors && errors["cnfPassword"] && (
                  <IonText color="danger" className="ion-padding-start">
                    <span style={{ color: "red", fontSize: "75%" }}>
                      {errors["cnfPassword"]}
                    </span>
                  </IonText>
                )}
              </div>
              <IonButton expand="block" type="submit" className="registerBtn">
                RESET PASSWORD
              </IonButton>
            </form>
            <p style={{ textAlign: "center" }}>
              Never mind.{" "}
              <a href="/login">
                <strong>Return to sign in.</strong>
              </a>
            </p>
          </div>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={10000}
      />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  authenticated: state.authenticated,
});

export default connect(mapStateToProps, {
  resetPassword,
})(ResetPassword);
