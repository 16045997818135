import React, { useState, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Linkify from "react-linkify";
import "./Note.css";
import {
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonPopover,
  IonModal,
  IonTextarea,
  IonItem,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import {
  ellipsisVerticalOutline,
  closeOutline,
  cameraOutline,
  eye,
} from "ionicons/icons";
import NotesIcon from "../assets/images/icons/Notes.svg";
import { deleteNote, updateNote } from "../redux/actions";
import axios from "axios";
import { Plugins } from "@capacitor/core";
import { CameraResultType, CameraSource } from "@capacitor/camera";

interface noteProps {
  currentNote: any;
  setShowPopover: any;
  showActions: boolean;
  renderEditModal: any;
  updateNote: any;
  deleteNote: any;
  key: any;
  shootNotes: any;
  selectedShoot: any;
  setContentFetched: any;
}

const Note: React.FC<noteProps> = ({
  currentNote,
  showActions,
  updateNote,
  deleteNote,
  key,
  shootNotes,
  selectedShoot,
  setContentFetched,
}) => {
  const [state, setState] = useState<any>({
    existingPhotos: [],
    newPhotos: [],
  });
  const { Camera } = Plugins;
  const [camera, setCamera] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>();
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const [editNoteText, setEditNoteText] = useState("");
  const { existingPhotos, newPhotos } = state;
  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
    });
    var imageUrl = `data:image/png;base64,${image.base64String}`;
    // Can be set to the src of an image now
    setCamera([...camera, imageUrl]);
  };
  const closeModal = () => {
    setCamera([]);
    setIsModalVisible(false);
  };
  const slideOpts = {
    zoom: {
      maxRatio: 3,
      toggle: true,
    },
  };
  const handleChange = async (noteId: any, type: String, image: any) => {
    setContentFetched(false);
    if (type === "YES") {
      const photos = currentNote.photos.gallery;
      const remove = currentNote.photos.gallery.filter(
        (pic: any) => pic !== image
      );
      const updatedPic = remove.filter((pic: any) => pic !== false);
      const reqBody = {
        ...currentNote,
        text: editNoteText !== "" ? editNoteText : currentNote.text,
        photos: {
          gallery: updatedPic,
        },
      };
      const res: any = await updateNote(noteId, reqBody);
      if (res.status === 200) {
        setContentFetched(true);
      }
      const deletePhotos = photos.filter(
        (target: any) => target.url !== noteId
      );
      setState({
        ...state,
        newPhotos: deletePhotos,
      });
    }
  };
  const handleEditNoteModal = (note: any) => {
    setEditNoteText(note?.text);
    setShowEditNoteModal(true);
  };
  const handleDeleteNote = async (note: any) => {
    setContentFetched(false);
    const res: any = await deleteNote(note._id);
    setShowPopover(false);
    if (res.status === 200) {
      setContentFetched(true);
    }
  };

  const showImageModal = (noteId: any) => {
    setSelectedNote(noteId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setPreviewImage(false);
    setIsModalVisible(false);
  };

  const handlePhotoEdit = () => {
    setCamera([]);
    setContentFetched(false);
    let notes = [...shootNotes];
    if (selectedNote !== "") {
      setIsModalVisible(false);
      const note = notes.filter((note: any) => note._id === selectedNote);

      if (Photos.length) {
        const handleUpload = async () => {
          let datas = new FormData();
          datas.append("path", `shoots/notes/high/${selectedShoot?.hsn}_`);
          for (var x = 0; x < Photos.length; x++) {
            const file = Photos[x].originFileObj;
            datas.append("file", file);
          }

          const upload = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/upload`,
            datas
          );

          const updatedPic = note[0].photos.gallery.concat(upload.data.urls);
          if (upload.status === 200) {
            setState({
              ...state,
              newPhotos: [],
            });
          }

          const reqBody = {
            text: editNoteText !== "" ? editNoteText : note[0].text,
            photos: {
              gallery: updatedPic,
            },
          };
          const res: any = await updateNote(selectedNote, reqBody);
          if (res.status === 200) {
            setContentFetched(true);
          }
          setSelectedNote("");
        };
        handleUpload();
      }
    }
  };

  let Photos: any;
  const handlePhotoChange = async (target: any) => {
    const newPhotos = [];
    for (let index = 0; index < target.length; index++) {
      const file = dataURLtoFile(target[index], "note-attachment" + Date.now());
      newPhotos.push({
        originFileObj: file,
        _id: existingPhotos.length + index + 1,
        order: existingPhotos.length + index + 1,
      });
    }
    Photos = newPhotos;
    setState({
      ...state,
      newPhotos: newPhotos,
    });
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const filetype = mime.substring(mime.lastIndexOf("/") + 1);
    return new File([u8arr], `${filename}.${filetype}`, { type: mime });
  }

  const handleUpdateNote = async (noteId: string) => {
    setContentFetched(false);
    const res: any = await updateNote(noteId, {
      text: editNoteText,
    });
    setEditNoteText("");
    setShowEditNoteModal(false);
    if (res.status === 200) {
      setContentFetched(true);
    }
  };
  const imageRemove = (image: any) => {
    const removedList = camera.filter((url: any) => url !== image);
    setCamera(removedList);
  };
  const renderEditModal = (note: any, key: any) => {
    return (
      <IonModal isOpen={showEditNoteModal}>
        <div className="modal-content">
          <div className="edit-note">
            <div className="modal-title">
              <span style={{ marginRight: 5 }}>
                <img src={NotesIcon} alt="Notes icon" width="22px" />
              </span>
              <h4>Edit Note</h4>
            </div>
            <IonTextarea
              value={editNoteText != null ? editNoteText : note.text}
              rows={2}
              required={true}
              onIonChange={(e) => setEditNoteText(e.detail.value!)}
            ></IonTextarea>
            <IonButton shape="round" onClick={() => handleUpdateNote(note._id)}>
              Save
            </IonButton>
            <IonButton
              color="primary"
              fill="outline"
              shape="round"
              onClick={() => {
                setShowEditNoteModal(false);
                setShowPopover(false);
              }}
            >
              Cancel
            </IonButton>
          </div>
        </div>
      </IonModal>
    );
  };

  const renderPopOver = (note: any) => {
    return (
      <>
        <IonPopover
          isOpen={showPopover}
          //cssClass="my-custom-class"
          onDidDismiss={(e) => setShowPopover(false)}
        >
          <IonGrid>
            <IonRow>
              <IonCol>
                {" "}
                <IonButton
                  size="small"
                  color="primary"
                  fill="clear"
                  className="close"
                  onClick={() => setShowPopover(false)}
                  style={{
                    float: "right",
                    paddingTop: "5px",
                    display: "block",
                  }}
                >
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  expand="block"
                  fill="outline"
                  onClick={() => (
                    showImageModal(note._id), setShowPopover(false)
                  )}
                >
                  Add Image
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  expand="block"
                  fill="outline"
                  onClick={() => (
                    handleEditNoteModal(note), setShowPopover(false)
                  )}
                >
                  Edit Note
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  color="danger"
                  expand="block"
                  fill="outline"
                  onClick={() => (
                    handleDeleteNote(note), setShowPopover(false)
                  )}
                >
                  Delete Note
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonPopover>
      </>
    );
  };

  return (
    <div className="currentNote">
      {showActions && (
        <div style={{ float: "right" }}>
          <IonButton
            size="small"
            color="primary"
            fill="clear"
            onClick={() => setShowPopover(true)}
          >
            <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
          </IonButton>
          {renderPopOver(currentNote)}
          {renderEditModal(currentNote, key)}
        </div>
      )}
      <div className="note-text-container">
        <span className="note-author"> {currentNote?.user?.name}</span>
        <span className="note-metadata">
          {moment(currentNote?._created_at).format("MMM D YYYY hh:mm A")}
        </span>
      </div>
      <div className="currentNote-text">
        <Linkify>{currentNote?.text}</Linkify>
      </div>
      <div className="customImageGallery">
        {currentNote.photos.gallery.map((image: any) => (
          <div className="imageWithClose" key={image}>
            <img src={image} alt={image} />
            <button
              className="view"
              onClick={() => (setPreviewImage(true), setCurrentImage(image))}
            >
              <IonIcon slot="icon-only" icon={eye} />
            </button>
            {showActions && (
              <>
                <button
                  className="close"
                  onClick={() => (setShowModal(true), setCurrentImage(image))}
                >
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </button>
              </>
            )}
          </div>
        ))}
        <IonPopover isOpen={showModal}>
          <div className="modal-content imagedelete-confirm">
            <p>Are you sure want to delete this image?</p>
            <IonButton
              size="small"
              fill="clear"
              onClick={() => (
                setShowModal(false),
                handleChange(currentNote?._id, "YES", currentImage)
              )}
            >
              Yes
            </IonButton>
            <IonButton
              size="small"
              fill="clear"
              onClick={() => setShowModal(false)}
            >
              No
            </IonButton>
          </div>
        </IonPopover>
        <IonModal
          //cssClass="imagePreview"
          isOpen={previewImage}
          onDidDismiss={handleCancel}
        >
          <div>
            <IonSlides options={slideOpts}>
              <IonSlide className="swiper-zoom-container">
                <img
                  style={{ maxHeight: "70vh" }}
                  src={currentImage}
                  alt={currentImage}
                />
              </IonSlide>
            </IonSlides>
            <IonButton color="light" onClick={handleCancel} size="small">
              <IonIcon slot="icon-only" icon={closeOutline} />
              Close
            </IonButton>
          </div>
        </IonModal>
        <IonModal isOpen={isModalVisible} onDidDismiss={handleCancel}>
          <div className="contentBody">
            {camera.length > 0 && (
              <IonItem>
                <div className="customImageGallery selectedImage">
                  {camera.map((image: any) => {
                    return (
                      <>
                        <div className="imageWithClose addImage">
                          <img
                            style={{
                              border: "1px solid black",
                              minHeight: "100px",
                              width: "100px",
                              height: "100px",
                            }}
                            src={image}
                            alt={image}
                          />
                          {showActions && (
                            <button
                              className="close"
                              onClick={() => imageRemove(image)}
                            >
                              <IonIcon slot="icon-only" icon={closeOutline} />
                            </button>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </IonItem>
            )}
          </div>
          <div style={{ padding: "30px" }}>
            <IonRow className="pt-2">
              <IonCol>
                <IonCol>
                  <IonButton
                    size="large"
                    shape="round"
                    color="light"
                    onClick={takePicture}
                  >
                    <IonIcon slot="icon-only" icon={cameraOutline} />
                  </IonButton>
                </IonCol>
              </IonCol>

              <IonCol>
                <IonButton
                  size="large"
                  onClick={() => (
                    handlePhotoChange(camera),
                    handlePhotoEdit(),
                    setIsModalVisible(false)
                  )}
                  className="float-right"
                >
                  Upload
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  fill="outline"
                  expand="block"
                  size="large"
                  onClick={closeModal}
                >
                  Cancel
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonModal>
      </div>
    </div>
  );
};

export default connect(null, {
  deleteNote,
  updateNote,
})(Note);
