import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "./PayBreakdown.css";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  arrowBackOutline,
  arrowForwardOutline,
  todayOutline,
} from "ionicons/icons";
import {
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
} from "../redux/actions";
import Accordion from "./Accordion";
import LabelDetail from "./LabelDetail";
import { photoCodes, roundedCurrency, roundOffNumber } from "../helpers/utils";
import { Col } from "@ionic/core/dist/types/components/col/col";

interface payProps {
  fetchPhotographerShoots: any;
  fetchDistanceArrayAndTotal: any;
  photographerShoots: any;
  auth: any;
}
const effectiveDate = "2022-04-04";
const isPastEffectiveDate = (shoot: any) => {
  const effectiveDateValue = new Date(effectiveDate);
  const fetchedDateValue = new Date(shoot?.events?.start);
  return fetchedDateValue >= effectiveDateValue;
};
const PayBreakdown: React.FC<payProps> = ({
  photographerShoots,
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
  auth,
}) => {
  const start = moment(moment(), "YYYY/MM/DD")
    .startOf("week")
    .subtract(2, "days");
  const end = moment().endOf("week").subtract(1, "days");
  const [time, setTime] = useState({
    start,
    end,
  });
  const effectiveDate = "2022-04-04";
  const costMultiplierForDist = 0.15;
  const effectiveDateValue = new Date(effectiveDate);
  const fetchedDateValue = new Date(photographerShoots[0]?.events?.start);
  const dateComparison = fetchedDateValue >= effectiveDateValue;
  const [grandTotal, setGrandTotal] = useState(0);
  const [distTravFns, setDistTravFns] = useState<any>({});
  const [weeklyDistance, setWeeklyDistance] = useState(0);

  const travelStipendAmount = 5;

  useEffect(() => {
    const photographerId = auth?.profile?._id;
    const startDate = moment(time.start).format("YYYY-MM-DD");
    const endDate = moment(time.end).format("YYYY-MM-DD");

    const filters = `startDate=${startDate}&endDate=${endDate}&status=Completed:Processing:Scheduled:Reschedule:Training`;
    if (photographerId) {
      fetchPhotographerShoots(photographerId, filters);
    }
  }, [auth, time]);

  // Calculate the weeklyDistance based on the distTravFns
  useEffect(() => {
    let tempWeeklyDistance = 0;
    for (const key in distTravFns) {
      tempWeeklyDistance += distTravFns[key];
    }
    setWeeklyDistance(tempWeeklyDistance);
  }, [distTravFns]);

  useEffect(() => {
    if (photographerShoots) {
      if (dateComparison) {
        let sortedArr = photographerShoots
          .filter((shoot: any) => {
            return (
              shoot.status === "Completed" ||
              shoot.status === "Processing" ||
              shoot.status === "Reschedule" ||
              shoot.status === "Training"
            );
          })
          .sort((a: any, b: any) => {
            if (a?.events?.start && b?.events?.start) {
              let firstDate = new Date(a.events.start);
              let secondDate = new Date(b.events.start);
              if (firstDate >= secondDate) return 1;
              else return -1;
            } else return 0;
          });
        //Segregate based on date
        const dateSeggregatedObj = sortedArr.reduce((acc: any, shoot: any) => {
          let datekey = moment(shoot.events.start).format("DD-MM-YYYY");
          return { ...acc, [datekey]: (acc[datekey] || []).concat(shoot) };
        }, {});

        //create distance calc array for each shoot.
        const setOfAddresses = Object.keys(dateSeggregatedObj).reduce(
          (acc: any, date: any) => {
            let dateWiseShoots = dateSeggregatedObj[date];
            let homeAddress: any = `${auth?.profile?.geometry?.coordinates[1]},${auth?.profile?.geometry?.coordinates[0]}`;
            const queryList = [
              homeAddress,
              ...dateWiseShoots.map(
                (shoot: any) =>
                  `${shoot?.address?.geometry?.coordinates[1]},${shoot?.address?.geometry?.coordinates[0]}`
              ),
            ];
            const shootDistMap: any = {};
            for (let i = 0; i < queryList.length - 1; i++) {
              (async () => {
                let res = await fetchDistanceArrayAndTotal(auth?.profile?._id, [
                  queryList[i],
                  queryList[i + 1],
                ]);

                if (i === queryList.length - 2) {
                  let tempRes = await fetchDistanceArrayAndTotal(
                    auth?.profile?._id,
                    [queryList[0], queryList[queryList.length - 1]]
                  );
                  res = {
                    data: { data: res?.data?.data + tempRes?.data?.data },
                    success: true,
                  };
                }

                if (res.success) {
                  let isAfterEffectiveDate = isPastEffectiveDate(
                    dateWiseShoots[i]
                  );
                  if (isAfterEffectiveDate) {
                    setDistTravFns((s: any) => ({
                      ...s,
                      [dateWiseShoots[i]._id]: res?.data?.data,
                    }));
                    setGrandTotal((s) => {
                      return (
                        s +
                        roundOffNumber(res?.data?.data * costMultiplierForDist)
                      );
                    });
                  }
                }
              })();
              dateWiseShoots[i]?._id &&
                (shootDistMap[dateWiseShoots[i]._id] = 0);
            }
            return { ...acc, ...shootDistMap };
          },
          {}
        );
      } //sort the completed orders in events.start ASC
      else {
        let grandTotal = 0;
        photographerShoots.map((shoot: any) =>
          shoot.invoice.map(
            (inv: any) =>
              (grandTotal +=
                inv.photographer_total_pay +
                getBasePay(shoot?.events?.start, shoot?.status) +
                (dateComparison ? travelStipendAmount : 0))
          )
        );
        setGrandTotal(parseFloat(grandTotal.toFixed(2)));
      }
    }
  }, [photographerShoots]);
  useEffect(() => {
    if (dateComparison) {
      if (photographerShoots.length > 0) {
        let grandTotal = 0;
        for (let i = 0; i < photographerShoots.length; i++) {
          let DistanceArrayMatch: any = [];
          let sum = 0;
          if (
            (photographerShoots[i]?.status === "Completed" ||
              photographerShoots[i]?.status === "Processing" ||
              photographerShoots[i]?.status === "Reschedule" ||
              photographerShoots[i]?.status === "Training") &&
            distTravFns[photographerShoots[i]?._id] !== undefined
          ) {
            let dist = distTravFns[photographerShoots[i]?._id];
            DistanceArrayMatch.push(dist);
          }
          for (var k = 0; k < DistanceArrayMatch.length; k++) {
            sum += roundOffNumber(
              DistanceArrayMatch[k] * costMultiplierForDist
            );
          }
          grandTotal +=
            photographerShoots[i].invoice[0].photographer_total_pay +
            getBasePay(
              photographerShoots[i]?.events?.start,
              photographerShoots[i]?.status
            ) +
            (dateComparison ? travelStipendAmount : 0) +
            sum;

          setGrandTotal(grandTotal);
        }
      }
    }
  }, [distTravFns]);

  // const basePay = 15 * auth?.profile?.pay_multiplier;

  // const calculateBasePay = (status: string) => {
  //   if (status === "Reschedule" || status === "Training") {
  //     return 0;
  //   } else {
  //     return basePay;
  //   }
  // };

  const prevBasePay = 15; // old base pay
  const cutOffDate = moment("2023-03-03"); // effective date for new base pay

  const getBasePay = (invoiceDate: string, status: string) => {
    const momentInvoiceDate = moment(invoiceDate);
    const payMultiplier = auth?.profile?.pay_multiplier || 1; // default to 1 if payMultiplier is not set
    if (status === "Reschedule" || status === "Training") {
      return 0;
    } else if (momentInvoiceDate.isBefore(cutOffDate)) {
      return parseFloat((prevBasePay * payMultiplier).toFixed(2));
    }
    return parseFloat((auth?.profile?.base_pay * payMultiplier).toFixed(2));
  };

  const dateHandlerNext = () => {
    setGrandTotal(0);
    return setTime({
      end: moment(time.end).add(7, "days"),
      start: moment(time.start).add(7, "days"),
    });
  };

  const dateHandlerPrev = () => {
    setGrandTotal(0);
    return setTime({
      start: moment(time.start).subtract(7, "days"),
      end: moment(time.end).subtract(7, "days"),
    });
  };

  const renderProducts = (val: any) => {
    const invoices = val.invoice;
    const data = [];
    let distanceAmount = distTravFns[val._id];
    for (let i = 0; i < invoices?.length; i++) {
      for (let j = 0; j < invoices[i].products.length; j++) {
        data.push(invoices[i].products[j]);
      }
    }
    return (
      <>
        {val?.status !== "Reschedule" && val.status !== "Training" && (
          <div style={{ width: "100%" }}>
            <LabelDetail
              label="Base"
              detail={getBasePay(val?.events?.start, val?.status).toFixed(2)}
            />
          </div>
        )}
        <div style={{ width: "100%" }}>
          {data.map(
            (product) =>
              product?.photographer_pay != null &&
              photoCodes?.includes(product.code) &&
              product?.photographer_pay > 0 && (
                <div key={product._id}>
                  <LabelDetail
                    label={product?.description}
                    detail={product?.photographer_pay.toFixed(2)}
                  />
                </div>
              )
          )}
        </div>
        {distTravFns &&
        distTravFns[val._id] !== undefined &&
        isPastEffectiveDate(val) &&
        val?.status !== "Postpone" ? (
          <div>
            <LabelDetail
              label={`Variable Trip Charge (${roundOffNumber(
                distanceAmount
              )} miles)`}
              detail={`${roundOffNumber(
                distanceAmount * costMultiplierForDist
              )}`}
            />
          </div>
        ) : null}
        {dateComparison ? (
          <div>
            <LabelDetail
              label="Driving"
              detail={(Math.round(travelStipendAmount * 100) / 100).toFixed(2)}
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="profile-pay">
      <div className="section-title">Pay Breakdown</div>
      <div>
        <IonGrid>
          <div className="calendar-nav">
            <div>
              <IonButton
                onClick={dateHandlerPrev}
                color="light"
                size="small"
                fill="outline"
              >
                <IonIcon slot="icon-only" icon={arrowBackOutline} />
              </IonButton>
            </div>
            <div className="pay-week">
              {moment(time.start).format("M/DD/YY")}-
              {moment(time.end).subtract(1, "days").format("M/DD/YY")}
            </div>
            <div>
              <IonButton
                onClick={dateHandlerNext}
                color="light"
                size="small"
                fill="outline"
              >
                <IonIcon slot="icon-only" icon={arrowForwardOutline} />
              </IonButton>
            </div>
          </div>
          <IonRow>
            {photographerShoots.map((shoot: any) => (
              <IonCol size="12" key={shoot._id}>
                <Accordion
                  title={
                    <div className="pay-title">
                      <span className="pay-day">
                        {moment(shoot?.events?.start).format("ddd, MM/DD")}
                      </span>{" "}
                      <span className="pay-hsn">HS# {shoot?.hsn}</span>
                      {(shoot?.status === "Completed" ||
                        shoot?.status === "Processing" ||
                        shoot?.status === "Reschedule" ||
                        shoot?.status === "Training") &&
                      dateComparison ? (
                        <span className="pay-amount">
                          {roundedCurrency(
                            shoot?.invoice[0]?.photographer_total_pay +
                              getBasePay(shoot?.events?.start, shoot?.status) +
                              (dateComparison ? travelStipendAmount : 0) +
                              Number(
                                distTravFns[shoot._id] * costMultiplierForDist
                              )
                          )}
                        </span>
                      ) : (
                        <span className="pay-amount">
                          {roundedCurrency(
                            shoot?.invoice[0]?.photographer_total_pay +
                              getBasePay(shoot?.events?.start, shoot?.status) +
                              (dateComparison ? travelStipendAmount : 0)
                          )}
                        </span>
                      )}
                    </div>
                  }
                  content={
                    <div className="pay-content">
                      <div className="pay-content-title">
                        <span>
                          {shoot?.address?.street.replace("null", "")}
                        </span>
                        <span style={{ float: "right", marginRight: 5 }}>
                          {`${shoot.client_name_first} ${shoot.client_name_last}`}
                        </span>
                      </div>
                      {renderProducts(shoot)}
                    </div>
                  }
                  icon={<IonIcon icon={todayOutline} />}
                  expand={false}
                />
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <div style={{ padding: 10, textAlign: "center" }}>
                <span style={{ fontSize: 14 }}>Week Total Distance: </span>
                <IonText color="primary">
                  <strong>{weeklyDistance}</strong>
                </IonText>{" "}
                miles
              </div>
            </IonCol>
            <IonCol size="12">
              <div style={{ padding: 10, textAlign: "center" }}>
                <span style={{ fontSize: 16 }}>Week Total: </span>
                <span className="week-total">
                  ${roundOffNumber(grandTotal)}
                </span>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    photographerShoots: state.photographerShoots,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
})(PayBreakdown);
