import React, { useEffect, useState } from "react";
import {
  IonInput,
  IonPage,
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonItem,
} from "@ionic/react";
import { connect } from "react-redux";
import { forgotPassword } from "../../redux/actions";
import logo from "../../assets/images/hs_logo_on_light.png";
import "./forgetPassword.css";
interface registerProps {
  forgotPassword: any;
  history: any;
}
const ForgetPassword: React.FC<registerProps> = ({
  history,
  forgotPassword,
}) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const sendLink = async () => {
    const response = await forgotPassword({
      email,
      entryPoint: "Photographer",
    });
    if (response.error) {
      setToastMessage(
        "Oops, Something went wrong. Kindly check you Email once again."
      );
      setShowToast(true);
      return;
    }
    setToastMessage("Your request has been sent. Please check your email.");
    setShowToast(true);
    history.push("/login");
  };

  return (
    <IonPage>
      <IonContent>
        <div className="reset-wrapper">
          <div className="reset-form">
            <IonGrid>
              <IonRow class="ion-align-items-center">
                <IonCol size="8" offset="2">
                  <div className="logo">
                    <img src={logo} alt="HomeSnappers" />
                  </div>
                </IonCol>
              </IonRow>
              <div className="reset-card">
                <p style={{ textAlign: "center", padding: "10px 10px 0 10px" }}>
                  Enter the email address you used to register and we’ll send
                  you instructions to reset your password.
                </p>
                {/* <form onSubmit={handleSubmit(sendLink)}> */}
                <IonRow>
                  <IonCol size="12">
                    <div className="reset-form">
                      <IonItem>
                        <IonInput
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onIonChange={(e) => setEmail(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow class="ion-align-items-center">
                  <IonCol>
                    <IonButton expand="block" type="submit" onClick={sendLink}>
                      SEND RESET LINK
                    </IonButton>
                    <p style={{ textAlign: "center" }}>
                      Never mind.{" "}
                      <a href="/login">
                        <strong>Return to sign in.</strong>
                      </a>
                    </p>
                  </IonCol>
                </IonRow>
                {/* </form> */}
              </div>
            </IonGrid>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={toastMessage}
              duration={10000}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect(null, {
  forgotPassword,
})(ForgetPassword);
