import React from "react";
import "./LabelDetail.css";
//import NumberFormat from "react-number-format";

interface labelDetailProps {
  label: string;
  detail: string;
}

const LabelDetail: React.FC<labelDetailProps> = ({ label, detail }) => {
  return (
    <div className="label-detail">
      <div className="label">{label}</div>
      <div className="detail">{detail} </div>
    </div>
  );
};

export default LabelDetail;
