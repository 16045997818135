import React from "react";

interface Props {
  shootType: string;
}

const ShootTypeBadge: React.FC<Props> = ({ shootType }) => {
  const badgeColor = () => {
    if (shootType === "Additional") {
      return { background: "#155baa" };
    } else if (shootType === "Commercial") {
      return { background: "#ffa4b6" };
    } else if (shootType === "Multi-family") {
      return { background: "#f765a3" };
    } else if (shootType === "Residential") {
      return { background: "#a155b9" };
    }
  };

  const badgeStyle: any = {
    ...badgeColor(),
    color: "white",
    marginLeft: 10,
    padding: "2px 5px",
    borderRadius: 10,
    fontSize: "12px",
  };

  return <span style={badgeStyle}>{shootType?.charAt(0)}</span>;
};

export default ShootTypeBadge;
